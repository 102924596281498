<template>
  <Card>
    <v-card flat class="px-7 pt-7 flex">
      <h1 class="midnightGreen--text">
        Product Images
      </h1>
      <v-row>
        <v-col
          lg="3"
          md="3"
          sm="6"
          cols="6"
          v-for="images in productImages"
          :key="images.id"
        >
          <v-list-item-content>
            <v-list-item-avatar
              tile
              size="130"
              class="rounded-tr-xl elevation-3"
            >
              <v-img :src="images.url"></v-img>
            </v-list-item-avatar>
          </v-list-item-content>
        </v-col>
      </v-row>
    </v-card>
    <BaseButton :to="`/company/${$route.params.id}`">Done</BaseButton>
  </Card>
</template>

<script>
import { mapActions, mapState } from "vuex";

import Card from "@/components/Card";
import BaseButton from "@/components/BaseButton";

export default {
  name: "ProductImagesView",
  components: {
    Card,
    BaseButton
  },
  created() {
    this.fetchSingleProduct({
      company: this.$route.params.id,
      product: this.$route.query.product
    });
  },
  computed: {
    ...mapState(["productImages"])
  },
  methods: {
    ...mapActions(["fetchSingleProduct"])
  }
};
</script>
