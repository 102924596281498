<template>
  <Card
    displayControls
    pathTo
    :location="
      $route.query.from ? () => $router.back() : () => $router.push('/profile')
    "
    :title="company_name | capitalize"
    :editable="mine ? mine : false"
    editMessage="Edit company"
    :to="`/company/${$route.params.id}/edit`"
  >
    <div class="px-5 pt-5 d-flex flex-row align-items-center">
      <v-avatar class="mr-3" :size="avatarSize" color="grey lighten-2">
        <v-img v-if="imageUrl" :src="imageUrl"></v-img>
        <v-icon v-else class="v-icon" size="90">mdi-account</v-icon>
      </v-avatar>
      <v-col v-if="myCompanies" class="pa-0">
        <div class="d-flex">
          <h3 class="font-weight-bold midnightGreen--text pr-2">
            {{ company_name | capitalize }}
          </h3>
          <v-tooltip v-if="company_status === 'validated'" top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" size="20" color="green" tile>
                mdi-shield-check
              </v-icon>
            </template>
            <span>Business verified</span>
          </v-tooltip>
          <v-tooltip v-if="company_status === 'not_validated'" top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" size="20" color="red" tile>
                mdi-shield-alert
              </v-icon>
            </template>
            <span>Business not verified</span>
          </v-tooltip>
        </div>
        <p class="text-muted">{{ company_type }}</p>
        <v-chip
          small
          class="text-uppercase"
          color="secondaryNeutral"
          text-color="midnightGreen"
          label
          >{{ location }}</v-chip
        >
      </v-col>
    </div>
    <v-divider inset></v-divider>
    <v-card flat class="px-5 flex">
      <h4 class="font-weight-bold midnightGreen--text">
        {{ mine ? "My Products" : "Products" }}
      </h4>
      <v-row class="mx-4" v-if="!productImages.length">
        <v-col cols="12" class="text-center">
          <img src="../assets/noProducts.png" style="width:85%;" />
          <p class="text-h6 midnightGreen--text font-weight-bold">
            No products uploaded yet
          </p>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col
          lg="6"
          md="6"
          sm="12"
          cols="12"
          v-for="images in productImages"
          :key="images.id"
        >
          <router-link
            :to="{
              path: '/product/' + images.productId,
              query: {
                company: $route.params.id
              }
            }"
          >
            <v-list-item-content>
              <v-list-item-avatar
                tile
                size="200"
                class="rounded-tr-xl elevation-1"
              >
                <v-img :src="images.url"></v-img>
              </v-list-item-avatar>
              <v-list-item-title class="text-h6 font-weight-normal">{{
                images.name
              }}</v-list-item-title>
              <v-list-item-title class="grey--text">{{
                "Free for delivery" || images.pricing[0].unit_price
              }}</v-list-item-title>
            </v-list-item-content>
          </router-link>
        </v-col>
      </v-row>
    </v-card>
    <BaseButton v-if="mine" :to="`/products/${$route.params.id}`"
      >Upload Products</BaseButton
    >
  </Card>
</template>

<script>
import { mapState, mapActions } from "vuex";

import Card from "@/components/Card";
import BaseButton from "@/components/BaseButton";

export default {
  name: "Company",
  components: {
    Card,
    BaseButton
  },
  data() {
    return {
      company_name: "",
      company_type: "",
      company_status: "",
      location: "",
      mine: true
    };
  },
  async created() {
    await this.fetchMyCompanies();
    await this.fetchCompanies();
    await this.fetchCompanyImageUrl(this.$route.params.id);
    await this.fetchProduct(this.$route.params.id);
    this.otherCompanyProducts();
  },
  computed: {
    ...mapState(["companies", "myCompanies", "imageUrl", "productImages"]),
    avatarSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "sm":
        case "xs":
          return "100";
        default:
          return "130";
      }
    }
  },
  methods: {
    ...mapActions([
      "fetchCompanyImageUrl",
      "fetchProduct",
      "fetchCompanies",
      "fetchMyCompanies"
    ]),
    otherCompanyProducts() {
      let company = this.myCompanies.find(
        company => parseInt(this.$route.params.id) === company.id
      );

      if (!company) {
        company = this.companies.find(
          company => parseInt(this.$route.params.id) === company.id
        );
      }

      if (company.mine === "N") {
        this.mine = false;
      }

      this.company_name = company.name;
      this.company_type = company.type;
      this.company_status = company.status;
      this.location = company.physical_location;
    }
  }
};
</script>
