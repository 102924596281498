<template>
  <Card classes="px-3 pt-3" displayControls title="Notifications" closeable>
    <v-list three-line nav>
      <NoItems
        v-if="!notifications.length"
        :isPopulated="!notifications.length"
      >
        No Notifications
      </NoItems>
      <v-list-item-group
        v-for="notification in notifications"
        :key="notification.notificationId"
        three-line
        v-else
      >
        <v-list-item
          @click="readNotification(notification)"
          :inactive="notification.status === 'read'"
          :class="colorNotification(notification)"
        >
          <v-badge
            :color="colorBadge(notification)"
            top
            dot
            offset-x="27"
            offset-y="27"
          >
            <v-list-item-avatar
              color="grey lighten-4"
              tile
              size="90"
              class="rounded-tr-xl"
            >
              <v-img
                v-if="notification.image"
                :src="notification.image"
              ></v-img>
              <v-icon v-else color="midnightGreen" size="60"
                >account_balance</v-icon
              >
            </v-list-item-avatar>
          </v-badge>
          <v-list-item-content>
            <v-list-item-title class="text-body-1">
              {{ notification.companyName }} has expressed interest in
              <span class="font-weight-bold text-capitalize"
                >{{ notification.productName }} -
                {{ notification.unitPrice }}</span
              >
              per
              {{ notification.unit | lowercase }}
            </v-list-item-title>
            <v-list-item-subtitle>{{
              notification.elapsedTime
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider inset></v-divider>
      </v-list-item-group>
    </v-list>
  </Card>
</template>

<script>
import { mapActions, mapState } from "vuex";

import Card from "@/components/Card";
import NoItems from "@/components/NoItems";

export default {
  name: "Notifications",
  components: {
    Card,
    NoItems
  },
  created() {
    this.fetchNotifications();
  },
  computed: mapState(["notifications"]),
  methods: {
    ...mapActions(["fetchNotifications", "notificationRead"]),
    colorNotification({ status }) {
      return status === "unread" ? "grey lighten-3" : "";
    },
    colorBadge({ status }) {
      return status === "unread" ? "#F8B234" : "";
    },
    readNotification({ notificationId, status, companyId, productId }) {
      if (status === "unread") {
        this.notificationRead(notificationId);
      }

      this.$router.push({
        path: "/interests-dashboard",
        query: {
          company: companyId,
          product: productId
        }
      });
    }
  }
};
</script>

<style scoped>
.v-list-item__title {
  white-space: normal;
}
</style>
