<template>
  <Card>
    <v-img
      class="mx-auto"
      src="../assets/House.svg"
      contain
      width="30%"
    ></v-img>
    <v-card-title class="midnightGreen--text text-h4 font-weight-bold mx-4"
      >Why we need your business information</v-card-title
    >
    <v-card-title class="midnightGreen--text text-body-1 mx-4">
      We use the information you give us in one way only; to verify the
      legitimacy of the businesses on our platform. This ensures that whoever
      trades on this platform can trust connections they make.
    </v-card-title>
    <BaseButton
      buttonColor="#35A19E"
      textColor="white"
      @click.native="$router.back"
      >Back to Setup</BaseButton
    >
  </Card>
</template>

<script>
import Card from "@/components/Card";
import BaseButton from "@/components/BaseButton";

export default {
  name: "BusinessInfo",
  components: {
    Card,
    BaseButton
  }
};
</script>

<style scoped>
.v-card__title {
  word-break: normal;
}
</style>
