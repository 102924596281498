var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Card',{attrs:{"displayControls":"","closeable":"","title":"Edit Company"}},[_c('ValidationObserver',{ref:"obs",staticClass:"flex d-flex flex-column justify-space-between",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var changed = ref.changed;
return [_c('v-card',{staticClass:"px-8 pt-5",attrs:{"flat":""}},[_c('ImageInput'),(_vm.company)?_c('v-form',[_c('ValidationProvider',{attrs:{"name":"Company","rules":"min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Company Name","placeholder":_vm.company.name,"required":""},model:{value:(_vm.formItems.name),callback:function ($$v) {_vm.$set(_vm.formItems, "name", $$v)},expression:"formItems.name"}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Company type","rules":"min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.items,"label":"Company type","placeholder":_vm.company.type,"required":""},model:{value:(_vm.formItems.type),callback:function ($$v) {_vm.$set(_vm.formItems, "type", $$v)},expression:"formItems.type"}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Company Email","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-5",attrs:{"label":"Company Email","placeholder":_vm.company.email,"required":""},model:{value:(_vm.formItems.email),callback:function ($$v) {_vm.$set(_vm.formItems, "email", $$v)},expression:"formItems.email"}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Business registration number","mode":"eager","rules":"min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input",attrs:{"type":"text","error-messages":errors,"label":"Business Registration Number","placeholder":_vm.company.business_regno,"required":""},model:{value:(_vm.formItems.business_regno),callback:function ($$v) {_vm.$set(_vm.formItems, "business_regno", $$v)},expression:"formItems.business_regno"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Business KRA Pin","rules":"min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-5",attrs:{"label":"Business KRA Pin","placeholder":_vm.company.kra_pin,"required":""},model:{value:(_vm.formItems.kra_pin),callback:function ($$v) {_vm.$set(_vm.formItems, "kra_pin", $$v)},expression:"formItems.kra_pin"}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Director's mobile number","rules":{ regex: /^\+2547\d{8}/g }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"maxlength":13,"label":"Director's mobile number","placeholder":_vm.company.phone_number,"required":""},model:{value:(_vm.formItems.phone_number),callback:function ($$v) {_vm.$set(_vm.formItems, "phone_number", $$v)},expression:"formItems.phone_number"}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Company address","rules":"min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Company address","placeholder":_vm.company.address,"required":""},model:{value:(_vm.formItems.address),callback:function ($$v) {_vm.$set(_vm.formItems, "address", $$v)},expression:"formItems.address"}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Company location","rules":"min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Company location","placeholder":_vm.company.physical_location,"required":""},model:{value:(_vm.formItems.physical_location),callback:function ($$v) {_vm.$set(_vm.formItems, "physical_location", $$v)},expression:"formItems.physical_location"}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_c('v-card',{staticClass:"d-flex align-items-center py-2",attrs:{"flat":""}},[_c('v-icon',{attrs:{"color":"#35A19E"}},[_vm._v("delete")]),_c('span',{staticClass:"midnightGreen--text delete--button text-decoration-underline text-capitalize text-h6 font-weight-normal",on:{"click":function($event){return _vm.confirmationAlert({
              company: _vm.$route.params.id,
              propertyToDelete: 'company'
            })}}},[_vm._v("Delete Company")])],1)],1),_c('BaseButton',{attrs:{"invalid":_vm.isValidForm(invalid, changed)},nativeOn:{"click":function($event){return _vm.submit($event)}}},[_vm._v("Save")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }