import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import { store } from "@/store";
import * as VeeValidate from "vee-validate";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import router from "./router";
import vuetify from "./plugins/vuetify";
import Vuetify from "vuetify";
import VueMatomo from "vue-matomo";
import VueGtag from "vue-gtag";

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

Vue.component("ValidationObserver", ValidationObserver),
  Vue.component("ValidationProvider", ValidationProvider);

Vue.config.productionTip = false;

Vue.use(VeeValidate);
Vue.use(Vuetify);
Vue.use(VueMatomo, {
  host: process.env.VUE_APP_MATOMO_URL,
  siteId: process.env.VUE_APP_MATOMO_SITE_ID,
  router: router,
  enableLinkTracking: true,
  requireConsent: false,
  trackInitialView: true,
  trackerFileName: "matomo"
});

Vue.use(
  VueGtag,
  {
    config: { id: process.env.VUE_APP_GA_CODE },
    pageTrackerTemplate(to) {
      return {
        page_path: to.path
      };
    }
  },
  router
);

Vue.filter("lowercase", value => value.toLowerCase());
Vue.filter("capitalize", value => {
  if (value) {
    return value
      .split(" ")
      .map(val => val[0].toUpperCase() + val.substring(1).toLowerCase())
      .join(" ");
  }
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
