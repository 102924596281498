<template>
  <v-app>
    <Loading v-if="isLoading" />
    <Navbar />
    <router-view />
  </v-app>
</template>

<script>
import Navbar from "./components/Navbar.vue";
import Loading from "./components/Loading.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    Navbar,
    Loading
  },
  computed: mapGetters(["isLoading"])
};
</script>

<style lang="scss">
html {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  -ms-overflow-style: none;
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
}

#app {
  max-height: 100%;
  margin: 0;
  padding: 0;
  background-image: url("./assets/Background.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  font-family: "Lato", sans-serif;
}

.v-btn:focus {
  outline: none;
  box-shadow: none;
}

.v-application {
  a {
    color: inherit !important;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
}

hr {
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.delete--button:hover {
  cursor: pointer;
}

.v-btn--uppercase-disable {
  text-transform: none;
}

.jukua {
  position: absolute;
  top: 0.5%;
}

.margin-sm {
  padding-top: 75%;
}

.margin-md {
  padding-top: 80%;
}

.margin-default {
  padding-top: 10%;
}
</style>
