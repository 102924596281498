var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Card',{attrs:{"displayControls":""}},[_c('ValidationObserver',{ref:"obs",staticClass:"main-container d-flex flex-column justify-content-between",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"px-7 pt-7"},[_c('h1',{staticClass:"midnightGreen--text"},[_vm._v(" Company "),_c('br'),_vm._v("Info ")]),_c('v-form',{staticClass:"offset-x my-10"},[_c('ValidationProvider',{attrs:{"name":"Company name","mode":"eager","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input",attrs:{"type":"text","id":"company_name","error-messages":errors,"label":"Company Name","required":""},model:{value:(_vm.form.company_name),callback:function ($$v) {_vm.$set(_vm.form, "company_name", $$v)},expression:"form.company_name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Company type","rules":"required","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"input",attrs:{"type":"text","id":"company_type","error-messages":errors,"items":_vm.items,"label":"Company Type","required":""},model:{value:(_vm.form.company_type),callback:function ($$v) {_vm.$set(_vm.form, "company_type", $$v)},expression:"form.company_type"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Company Email","mode":"eager","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input",attrs:{"type":"text","error-messages":errors,"label":"Company Email","required":""},model:{value:(_vm.form.company_email),callback:function ($$v) {_vm.$set(_vm.form, "company_email", $$v)},expression:"form.company_email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Business registration number","mode":"eager","rules":"min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input",attrs:{"type":"text","error-messages":errors,"label":"Business Registration Number","required":""},model:{value:(_vm.form.company_registration),callback:function ($$v) {_vm.$set(_vm.form, "company_registration", $$v)},expression:"form.company_registration"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Business KRA Pin","mode":"eager","rules":"min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input",attrs:{"type":"text","error-messages":errors,"label":"Business KRA Pin","required":""},model:{value:(_vm.form.company_kra),callback:function ($$v) {_vm.$set(_vm.form, "company_kra", $$v)},expression:"form.company_kra"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Director's mobile number","rules":{ required: true, regex: /^07\d{8}$/ },"mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input",attrs:{"type":"text","maxLength":10,"error-messages":errors,"label":"Director's Mobile Number (07XXXXXXXX)","required":""},model:{value:(_vm.form.phone_number),callback:function ($$v) {_vm.$set(_vm.form, "phone_number", $$v)},expression:"form.phone_number"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Company address","rules":"required|min:5","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input",attrs:{"type":"text","error-messages":errors,"label":"Company Address","required":""},model:{value:(_vm.form.company_address),callback:function ($$v) {_vm.$set(_vm.form, "company_address", $$v)},expression:"form.company_address"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Company location","rules":"required|min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input",attrs:{"type":"text","error-messages":errors,"label":"Company Location","required":""},model:{value:(_vm.form.company_location),callback:function ($$v) {_vm.$set(_vm.form, "company_location", $$v)},expression:"form.company_location"}})]}}],null,true)}),_c('div',{staticClass:"midnightGreen--text text-body-1 text-decoration-underline"},[_c('router-link',{staticClass:"router-link",attrs:{"to":"/why-business-info"}},[_vm._v("Why do we need this information?")])],1)],1)],1),_c('div',[_c('CompanyCreationNotice',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"midnightGreen--text py-10 text-h6 text-capitalize font-weight-bold rounded-corner",attrs:{"tile":"","width":_vm.buttonWidth,"large":"","color":"#F8B234","disabled":invalid}},'v-btn',attrs,false),on),[_vm._v(" Submit ")])]}}],null,true)})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }