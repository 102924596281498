<template>
  <Card displayControls notifications>
    <ValidationObserver
      class="d-flex flex flex-column justify-space-between"
      ref="observer"
      v-slot="{ invalid }"
    >
      <v-card class="px-10 pt-7 flex" flat>
        <h1 class="midnightGreen--text">
          Pricing
          <br />Product
        </h1>

        <v-form>
          <v-row>
            <v-col cols="5">
              <ValidationProvider
                name="Price"
                rules="required|numeric"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model.number="from_price"
                  class="input"
                  type="text"
                  label="From price in Kshs"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="2">
              <p class="mt-4 font-weight-medium text-center to">
                to
              </p>
            </v-col>
            <v-col cols="5">
              <ValidationProvider
                name="Price"
                rules="required|numeric"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model.number="to_price"
                  class="input"
                  type="text"
                  label="Price in Kshs"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>
          <ValidationProvider
            name="Quantity"
            rules="required|numeric"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model.number="min_units"
              label="Minimum Order Quantity"
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider
            name="Cost of Delivery"
            rules="required|min:3"
            v-slot="{ errors }"
          >
            <v-select
              v-model="delivery_cost"
              label="Cost of Delivery"
              :items="deliveryCosts"
              :error-messages="errors"
              class="input"
            ></v-select>
          </ValidationProvider>
          <h3 class="midnightGreen--text">Pricing Flexibility</h3>
          <v-row>
            <v-col>
              <p>Enable discounting</p>
            </v-col>
            <v-col class="d-flex align-items-end">
              <v-switch v-model="enable_discounting"></v-switch>
            </v-col>
          </v-row>
          <ValidationProvider
            name="Discount Threshhold"
            rules="required|numeric"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model.number="discount_threshhold"
              label="Minimum Quantity for discount"
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
        </v-form>
      </v-card>
      <BaseButton
        buttonColor="blueGreen"
        textColor="white"
        :invalid="invalid"
        @click.native="
          addPricing({
            id: $route.params.productId,
            company: $route.params.companyId,
            route: $route.query.route,
            data: { from_price, to_price, min_units, delivery_cost }
          })
        "
        >Next</BaseButton
      >
    </ValidationObserver>
  </Card>
</template>

<script>
import { mapActions, mapState } from "vuex";

import Card from "@/components/Card";
import BaseButton from "@/components/BaseButton";

export default {
  name: "ProductPricing",
  components: {
    Card,
    BaseButton
  },
  data() {
    return {
      from_price: "",
      to_price: "",
      min_units: "",
      discount_threshhold: "",
      enable_discounting: "",
      delivery_cost: "",
      deliveryCosts: [
        "Free in all areas",
        "Free in some areas",
        "User pay for delivery"
      ]
    };
  },
  created() {
    this.fetchProduct(this.$route.params.companyId);
  },
  computed: mapState(["productId"]),
  methods: {
    ...mapActions(["fetchProduct", "addPricing"])
  }
};
</script>
