<template>
  <Card displayControls closeable title="Edit Company">
    <ValidationObserver
      class="flex d-flex flex-column justify-space-between"
      ref="obs"
      v-slot="{ invalid, changed }"
    >
      <v-card flat class="px-8 pt-5">
        <ImageInput />
        <v-form v-if="company">
          <ValidationProvider name="Company" rules="min:3" v-slot="{ errors }">
            <v-text-field
              v-model="formItems.name"
              label="Company Name"
              :placeholder="company.name"
              required
            ></v-text-field>
            <span class="error">{{ errors[0] }}</span>
          </ValidationProvider>
          <ValidationProvider
            name="Company type"
            rules="min:3"
            v-slot="{ errors }"
          >
            <v-select
              v-model="formItems.type"
              :items="items"
              label="Company type"
              :placeholder="company.type"
              required
            ></v-select>
            <span class="error">{{ errors[0] }}</span>
          </ValidationProvider>
          <ValidationProvider
            name="Company Email"
            rules="email"
            v-slot="{ errors }"
          >
            <v-text-field
              class="mb-5"
              v-model="formItems.email"
              label="Company Email"
              :placeholder="company.email"
              required
            ></v-text-field>
            <span class="error">{{ errors[0] }}</span>
          </ValidationProvider>
          <ValidationProvider
            name="Business registration number"
            mode="eager"
            rules="min:5"
            v-slot="{ errors }"
          >
            <v-text-field
              class="input"
              type="text"
              v-model="formItems.business_regno"
              :error-messages="errors"
              label="Business Registration Number"
              :placeholder="company.business_regno"
              required
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider
            name="Business KRA Pin"
            rules="min:5"
            v-slot="{ errors }"
          >
            <v-text-field
              class="mb-5"
              v-model="formItems.kra_pin"
              label="Business KRA Pin"
              :placeholder="company.kra_pin"
              required
            ></v-text-field>
            <span class="error">{{ errors[0] }}</span>
          </ValidationProvider>
          <ValidationProvider
            name="Director's mobile number"
            :rules="{ regex: /^\+2547\d{8}/g }"
            v-slot="{ errors }"
          >
            <v-text-field
              :maxlength="13"
              v-model="formItems.phone_number"
              label="Director's mobile number"
              :placeholder="company.phone_number"
              required
            ></v-text-field>
            <span class="error">{{ errors[0] }}</span>
          </ValidationProvider>
          <ValidationProvider
            name="Company address"
            rules="min:5"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="formItems.address"
              label="Company address"
              :placeholder="company.address"
              required
            ></v-text-field>
            <span class="error">{{ errors[0] }}</span>
          </ValidationProvider>
          <ValidationProvider
            name="Company location"
            rules="min:4"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="formItems.physical_location"
              label="Company location"
              :placeholder="company.physical_location"
              required
            ></v-text-field>
            <span class="error">{{ errors[0] }}</span>
          </ValidationProvider>
        </v-form>
        <v-card flat class="d-flex align-items-center py-2">
          <v-icon color="#35A19E">delete</v-icon>
          <span
            @click="
              confirmationAlert({
                company: $route.params.id,
                propertyToDelete: 'company'
              })
            "
            class="midnightGreen--text delete--button text-decoration-underline text-capitalize text-h6 font-weight-normal"
            >Delete Company</span
          >
        </v-card>
      </v-card>
      <BaseButton
        @click.native="submit"
        :invalid="isValidForm(invalid, changed)"
        >Save</BaseButton
      >
    </ValidationObserver>
  </Card>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

import Card from "@/components/Card";
import BaseButton from "@/components/BaseButton";
import ImageInput from "@/components/ImageInput";

export default {
  name: "EditCompany",
  components: {
    Card,
    BaseButton,
    ImageInput
  },
  data() {
    return {
      formItems: {
        name: "",
        type: "",
        phone_number: "",
        address: "",
        physical_location: "",
        email: "",
        kra_pin: "",
        business_regno: ""
      },
      items: ["Limited Company", "Limited Liability Partnership", "Business"]
    };
  },
  created() {
    this.fetchCompanyProfile(this.$route.params.id);
    this.fetchCompanyDocumentUuids();
  },
  computed: {
    ...mapState(["company", "previewImage"]),
    ...mapGetters(["getPreviewImage"])
  },

  methods: {
    ...mapActions([
      "fetchCompanyProfile",
      "fetchCompanyDocumentUuids",
      "uploadFiles",
      "updateCompany",
      "confirmationAlert"
    ]),
    submit() {
      if (this.previewImage) {
        this.uploadFiles({
          id: this.$route.params.id,
          category: "company"
        });
      }

      const filterEmptyFormValues = Object.entries(this.formItems).filter(
        form => {
          return !!form[1];
        }
      );

      if (Object.entries(filterEmptyFormValues).length) {
        const filteredObjected = Object.fromEntries(filterEmptyFormValues);
        this.updateCompany({
          id: this.$route.params.id,
          formItems: filteredObjected
        });
      }
    },
    isValidForm(invalid, changed) {
      if (!invalid && changed) {
        return false;
      }

      if (this.previewImage) {
        return false;
      }

      return true;
    }
  }
};
</script>
