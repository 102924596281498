<template>
  <Card displayControls notifications>
    <ValidationObserver
      class="d-flex flex flex-column justify-content-between"
      ref="observer"
      v-slot="{ invalid }"
    >
      <v-card flat class="px-6 pt-6 flex">
        <h1 class="midnightGreen--text">
          Upload
          <br />Product
        </h1>
        <v-form>
          <ValidationProvider
            name="Product name"
            rules="required|min:3"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model.number="product_name"
              label="Product Name"
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider
            name="Category"
            rules="required|min:3"
            v-slot="{ errors }"
          >
            <v-select
              v-model="category"
              :items="categories"
              label="Category"
              :error-messages="errors"
              required
            ></v-select>
          </ValidationProvider>
          <ValidationProvider
            v-if="category === 'Other'"
            name="Other Category"
            rules="required|min:3"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="category_other"
              label="Other category"
              :error-messages="errors"
              type="text"
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider
            name="Unit of measurement"
            mode="eager"
            rules="required|min:2"
            v-slot="{ errors }"
          >
            <v-select
              class="input-field"
              type="text"
              v-model="unit"
              :items="units"
              :error-messages="errors"
              label="Unit of Measurement"
              required
            ></v-select>
          </ValidationProvider>
          <ValidationProvider
            name="Unit of measurement"
            v-if="unit === 'Other'"
            rules="required|min:2"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="unit_other"
              label="Other unit of measurement"
              :error-messages="errors"
              class="input"
              type="text"
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider
            name="Quantity"
            rules="required|numeric"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model.number="quantity"
              label="Quantity"
              :error-messages="errors"
              class="input"
              type="text"
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider
            name="Location of product"
            rules="required|min:4"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="location"
              label="Location of Product"
              :error-messages="errors"
              class="input"
              type="text"
            ></v-text-field>
          </ValidationProvider>
        </v-form>
      </v-card>
      <BaseButton
        @click.native="
          addProduct({
            id: $route.params.id,
            data: {
              name: product_name,
              category: category_other ? category_other : category,
              unit: unit_other ? unit_other : unit,
              quantity,
              location
            }
          })
        "
        :invalid="invalid"
        buttonColor="blueGreen"
        textColor="white"
        >Next</BaseButton
      >
    </ValidationObserver>
  </Card>
</template>

<script>
import { mapActions } from "vuex";

import Card from "@/components/Card";
import BaseButton from "@/components/BaseButton";

export default {
  name: "UploadProduct",
  components: {
    Card,
    BaseButton
  },
  data() {
    return {
      product_name: "",
      category: "",
      category_other: "",
      unit: "",
      unit_other: "",
      quantity: "",
      location: "",
      categories: ["Agriculture", "Electronics", "Fashion", "Other"],
      units: ["KG", "Litres", "Crates", "Dozens", "Other"]
    };
  },
  methods: mapActions(["addProduct"])
};
</script>
