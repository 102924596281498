<template>
  <v-container class="card-container pa-0 d-flex align-end justify-center">
    <Logo :hide="hide" />
    <PageControls
      v-if="displayControls"
      :title="title"
      :to="to"
      :editable="editable"
      :closeable="closeable"
      :notifications="notifications"
      :interests="interests"
      :editMessage="editMessage"
      :location="location"
      :pathTo="pathTo"
    />
    <v-card
      class="d-flex flex-column rounded-tr-xl overflow-hidden"
      tile
      flat
      :width="width"
      :height="cardHeight || height"
    >
      <div class="pt-1 flex overflow-y-auto">
        <v-card
          flat
          height="100%"
          :class="`${classes} pt-1 d-flex flex flex-column`"
        >
          <slot />
        </v-card>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import Logo from "./Logo";
import PageControls from "./PageControls";

export default {
  name: "Card",
  components: {
    Logo,
    PageControls
  },
  props: {
    cardHeight: String,
    cardWidth: String,
    classes: String,
    displayControls: Boolean,
    title: String,
    to: [String, Object],
    closeable: Boolean,
    editable: Boolean,
    notifications: Boolean,
    interests: Boolean,
    editMessage: String,
    location: Function,
    pathTo: Boolean,
    hide: Boolean
  },
  data: () => ({
    height: "80%"
  }),
  computed: {
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case "sm":
        case "xs":
          return "100%";
        default:
          return this.cardWidth ? this.cardWidth : "60%";
      }
    }
  }
};
</script>

<style scoped>
.card-container {
  height: 100vh;
  position: relative;
}
</style>
