export const getters = {
  isLoggedIn: state => !!state.token,
  isLoading: state => state.isLoading,
  isNext: state => state.next,
  isEmpty: state => state.isEmpty,
  getProfile: state => state.profile,
  getInterests: state => state.interests,
  getProducts: state => state.products,
  getCompanies: state => state.companies.filter(company => company.mine),
  getCompanyProducts: state => state.companyProducts,
  getUploadUuid: state => state.uploadUuid,
  getAlert: state => state.alert,
  getTrainingContent: state => state.trainingContent,
  getPreviewImage: state => state.previewImage,
  getTrainingContentById: state => state.trainingContentById,
  getIsSubmitted: state => state.isSubmitted,
  getIsInterested: state => state.isInterested,
  getfilteredTags: state => state.filteredTraining,
  getPricing: state => state.pricing,
  getProductImages: state => state.productImages
};
