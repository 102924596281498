<template>
  <Card displayControls title="Edit Product">
    <ProductImage :companyProducts="companyProducts" editable />
    <ImageGrid :productImages="productImages" />
    <ValidationObserver
      class="d-flex flex flex-column justify-space-between"
      ref="observer"
      v-slot="{ invalid, changed }"
    >
      <v-form class="px-10" v-if="companyProducts">
        <ValidationProvider
          name="Product name"
          rules="min:3"
          v-slot="{ errors }"
        >
          <v-text-field
            v-model="name"
            label="Product Name"
            :error-messages="errors"
            :placeholder="companyProducts.name"
            required
          ></v-text-field>
        </ValidationProvider>
        <ValidationProvider name="Category" rules="min:3" v-slot="{ errors }">
          <v-select
            v-model="category"
            :items="categories"
            label="Category"
            :error-messages="errors"
            :placeholder="companyProducts.category"
            required
          ></v-select>
        </ValidationProvider>
        <ValidationProvider
          v-if="category === 'Other'"
          name="Other Category"
          rules="min:3"
          v-slot="{ errors }"
        >
          <v-text-field
            v-model="category_other"
            label="Other category"
            :error-messages="errors"
            type="text"
          ></v-text-field>
        </ValidationProvider>
        <ValidationProvider
          name="Unit of measurement"
          mode="eager"
          rules="min:2"
          v-slot="{ errors }"
        >
          <v-select
            class="input-field"
            type="text"
            v-model="unit"
            :items="units"
            :error-messages="errors"
            label="Unit of Measurement"
            :placeholder="companyProducts.unit"
            required
          ></v-select>
        </ValidationProvider>
        <ValidationProvider
          name="Unit of measurement"
          v-if="unit === 'Other'"
          rules="min:2"
          v-slot="{ errors }"
        >
          <v-text-field
            v-model="unit_other"
            label="Other unit of measurement"
            :error-messages="errors"
            class="input"
            type="text"
          ></v-text-field>
        </ValidationProvider>
        <ValidationProvider name="Quantity" rules="numeric" v-slot="{ errors }">
          <v-text-field
            v-model.number="quantity"
            label="Quantity"
            :error-messages="errors"
            :placeholder="companyProducts.quantity"
            class="input"
            type="text"
          ></v-text-field>
          <span class="error">{{ errors[0] }}</span>
        </ValidationProvider>
        <ValidationProvider
          name="Location of product"
          rules="min:4"
          v-slot="{ errors }"
        >
          <v-text-field
            v-model="location"
            label="Location of Product"
            :error-messages="errors"
            :placeholder="companyProducts.location"
            class="input"
            type="text"
          ></v-text-field>
        </ValidationProvider>
      </v-form>
      <v-list class="px-6" three-line nav>
        <v-list-item-group v-for="price in getPricing" :key="price.id">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item
                v-bind="attrs"
                v-on="on"
                router
                :to="{
                  path: `/product/${$route.params.id}/pricing/${price.id}`,
                  query: {
                    company: $route.query.company
                  }
                }"
              >
                <v-list-item-content>
                  <v-list-item-title
                    class="text-h6 font-weight-normal blueGreen--text"
                    >{{ price.unit_price }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    per {{ price.min_units | lowercase }} &#9679; Min. Order
                    {{ price.min_units }}
                  </v-list-item-subtitle>
                  <v-list-item-title class="text-subtitle-1">
                    {{ price.delivery_cost }}</v-list-item-title
                  >
                </v-list-item-content>
                <v-icon fab color="#F8B234">mdi-pencil</v-icon>
              </v-list-item>
            </template>
            <span>Edit pricing</span>
          </v-tooltip>
          <v-divider inset></v-divider>
        </v-list-item-group>
      </v-list>
      <v-card flat class="d-flex align-items-center px-5 pb-5">
        <v-icon size="30" color="#35A19E">mdi-plus-circle</v-icon>
        <span
          @click="
            $router.push({
              path: `/products/${$route.query.company}/pricing/${$route.params.id}`,
              query: {
                route: `/product/${$route.params.id}/edit?company=${$route.query.company}`
              }
            })
          "
          class="midnightGreen--text delete--button text-decoration-underline text-capitalize text-h6 font-weight-normal"
          >Add Another Product Price</span
        >
      </v-card>
      <BaseButton
        buttonColor="blueGreen"
        textColor="white"
        :invalid="isUpdated(invalid, changed)"
        @click.native="
          updateProduct({
            companyId: $route.query.company,
            data: {
              name: name || companyProducts.name,
              category: category_other
                ? category_other
                : category || companyProducts.category,
              unit: unit_other ? unit_other : unit || companyProducts.unit,
              quantity: quantity || companyProducts.quantity,
              location: location || companyProducts.location,
              product_id: $route.params.id
            }
          })
        "
        >Save</BaseButton
      >
    </ValidationObserver>
  </Card>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

import Card from "@/components/Card";
import BaseButton from "@/components/BaseButton";
import ProductImage from "@/components/ProductImage";
import ImageGrid from "@/components/ImageGrid";

export default {
  name: "EditProductOrPricing",
  components: {
    Card,
    BaseButton,
    ProductImage,
    ImageGrid
  },
  data() {
    return {
      name: "",
      category: "",
      category_other: "",
      unit: "",
      unit_other: "",
      quantity: "",
      location: "",
      pricingId: "",
      categories: ["Agriculture", "Electronics", "Fashion", "Other"],
      units: ["KG", "Litres", "Crates", "Dozens", "Other"]
    };
  },
  created() {
    this.fetchSingleProduct({
      company: this.$route.query.company,
      product: this.$route.params.id
    });
  },
  computed: {
    ...mapState(["companyProducts", "productImages"]),
    ...mapGetters(["getCompanyProducts", "getPricing"])
  },
  methods: {
    ...mapActions(["fetchSingleProduct", "updateProduct"]),
    isUpdated(invalid, changed) {
      if (!invalid && changed) {
        return false;
      }

      return true;
    }
  }
};
</script>

<style scoped>
.v-text-field--placeholder {
  color: red !important;
  opacity: 1;
}
</style>
