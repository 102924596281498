<template>
  <Success v-if="registered" />
  <Card cardWidth="50%" v-else>
    <ValidationObserver class="px-10 pt-10 pb-5" ref="obs" v-slot="{ invalid }">
      <v-card-title class="px-0 pb-10 midnightGreen--text text-h3">
        Personal
        <br />Info
      </v-card-title>
      <v-form>
        <ValidationProvider
          name="Name"
          mode="eager"
          rules="required"
          v-slot="{ errors }"
        >
          <v-text-field
            class="input"
            type="text"
            v-model.trim="name"
            :error-messages="errors"
            label="Name"
            required
          ></v-text-field>
        </ValidationProvider>
        <ValidationProvider
          name="Email"
          mode="eager"
          rules="required|email"
          v-slot="{ errors }"
        >
          <v-text-field
            class="input"
            type="email"
            v-model.trim="email"
            :error-messages="errors"
            label="Email"
            required
          ></v-text-field>
        </ValidationProvider>
        <ValidationProvider
          name="ID/Passport"
          mode="eager"
          rules="required|min:7"
          v-slot="{ errors }"
        >
          <v-text-field
            class="input"
            type="text"
            name="ID/Passport"
            v-model.trim="idPassport"
            :error-messages="errors"
            label="ID/Passport"
            required
          ></v-text-field>
        </ValidationProvider>
        <ValidationProvider
          name="Password"
          vid="confirm"
          rules="required|min:5"
          v-slot="{ errors }"
        >
          <v-text-field
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            v-model.trim="password"
            :error-messages="errors"
            label="Password"
            required
          ></v-text-field>
        </ValidationProvider>
        <ValidationProvider
          name="Confirmation password"
          mode="eager"
          rules="required|confirmed:confirm"
          v-slot="{ errors }"
        >
          <v-text-field
            :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showConfirmPassword ? 'text' : 'password'"
            @click:append="showConfirmPassword = !showConfirmPassword"
            v-model.trim="passwordConfirmed"
            :error-messages="errors"
            label="Confirm Password"
            required
          ></v-text-field>
        </ValidationProvider>

        <ValidationProvider :rules="{ required: { allowFalse: false } }">
          <div>
            <v-checkbox v-model="checkbox">
              <template v-slot:label>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click="dialog = true"
                      text
                      class="mt-2 text--secondary v-btn--uppercase-disable text-wrap agree-btn"
                      v-on="on"
                      >I agree to Jukua's terms and conditions</v-btn
                    >
                  </template>
                  Click to read our Terms and Conditions.
                  <br />Note: You cannot proceed if you do not Agree.
                </v-tooltip>
              </template>
            </v-checkbox>
          </div>
          <v-dialog v-model="dialog" width="600px">
            <v-card>
              <v-card-text>
                <TermsandConditions class="pt-4" />
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="green darken-1"
                  class="font-weight-bold"
                  text
                  @click="(dialog = false), (checkbox = false)"
                  >Disagree</v-btn
                >
                <v-btn
                  color="green darken-1"
                  class="font-weight-bold"
                  text
                  @click="(dialog = false), (checkbox = true)"
                  >Agree</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </ValidationProvider>
      </v-form>
      <v-card
        tile
        flat
        class="text-h6 d-flex justify-space-between align-center signUp pt-5 pb-3"
      >
        <v-card-text class="pa-0 text-h6 midnightGreen--text"
          >Sign Up</v-card-text
        >
        <v-btn
          class="mx-2"
          fab
          large
          color="primary"
          @click="registration({ name, email, idPassport, password })"
          :disabled="invalid"
        >
          <v-icon color="black">mdi-arrow-right</v-icon>
        </v-btn>
      </v-card>
      <router-link class="text-h6 text-underline-offset" to="/login"
        >Sign in</router-link
      >
    </ValidationObserver>
  </Card>
</template>

<script>
import { mapState, mapActions } from "vuex";

import Card from "@/components/Card";
import Success from "@/components/Success";
import TermsandConditions from "@/components/TermsandConditions";

export default {
  name: "Register",
  components: {
    Card,
    Success,
    TermsandConditions
  },
  data() {
    return {
      message: "",
      name: "",
      email: "",
      idPassport: "",
      password: "",
      passwordConfirmed: "",
      showPassword: false,
      showConfirmPassword: false,
      checkbox: false,
      dialog: false
    };
  },
  computed: mapState(["registered"]),
  watch: {
    name(value) {
      if (value) {
        this.name = value
          .split(" ")
          .map(name => name[0].toUpperCase() + name.substring(1).toLowerCase())
          .join(" ");
      }
    }
  },
  methods: mapActions(["registration"]),
  terms() {
    this.dialog = true;
    this.checkbox = false;
  }
};
</script>

<style scoped>
.text-underline-offset {
  border-style: solid;
  border-width: 0;
  border-bottom-width: 1.8px;
}

.text-nobreak {
  word-break: normal;
}
@media only screen and (max-width: 441px) {
  .agree-btn {
    font-size: 2vh;
    margin-left: -18px;
  }
  .signUp {
    margin-top: -20px !important;
    margin-bottom: 20px;
  }
}
</style>
