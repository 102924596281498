<template>
  <v-list v-if="productImages" class="px-6">
    <v-list-item class="d-flex overflow-x-auto">
      <v-tooltip top>
        <template v-slot:activator="{ attrs, on }">
          <v-list-item-avatar
            v-bind="attrs"
            v-on="on"
            size="100"
            color="brown lighten-5"
            class="rounded-0 rounded-lg"
            @click="
              $router.push(
                `/products/${$route.query.company}/upload?product=${$route.params.id}`
              )
            "
          >
            <v-icon size="100" color="#F8B234">mdi-plus</v-icon>
          </v-list-item-avatar>
        </template>
        <span>Add image</span>
      </v-tooltip>
      <v-list-item-group v-for="image in productImages" :key="image.id">
        <v-list-item-avatar class="rounded-0 rounded-lg elevation-3" size="100">
          <v-img :src="image.url"></v-img>
        </v-list-item-avatar>
      </v-list-item-group>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "ImageGrid",
  props: {
    productImages: Array
  }
};
</script>
