<template>
  <Card>
    <v-img
      class="mx-auto"
      src="../assets/Register.svg"
      contain
      width="30%"
    ></v-img>
    <v-card-title class="midnightGreen--text text-h4 font-weight-bold mx-4"
      >Register Your Business</v-card-title
    >
    <v-card-title class="midnightGreen--text text-body-1 mx-4">
      Setup your business and have it verified on Jukua to be able to get the
      most from the marketplace
    </v-card-title>
    <v-card-title
      class="midnightGreen--text text-body-1 text-decoration-underline mx-4"
    >
      <router-link class="router-link" to="/dashboard"
        >Register Later</router-link
      >
    </v-card-title>
    <BaseButton to="/company-registration">Setup Company</BaseButton>
  </Card>
</template>

<script>
import Card from "@/components/Card";
import BaseButton from "@/components/BaseButton";

export default {
  name: "RegisterOnboarding",
  components: {
    Card,
    BaseButton
  }
};
</script>

<style scoped>
.v-card__title {
  word-break: normal;
}
</style>
