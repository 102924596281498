import Vue from "vue";
import Router from "vue-router";

import Login from "@/views/Login";
import Register from "@/views/Register";
import Home from "@/views/Home";
import FAQs from "@/views/FAQs";
import Dashboard from "@/views/Dashboard";
import ProductPricing from "@/views/ProductPricing";
import Interest from "@/views/Interest";
import Interests from "@/views/Interests";
import UploadProduct from "@/views/UploadProduct";
import ProductImageUpload from "@/views/ProductImageUpload";
import Training from "@/views/Training";
import TrainingContent from "@/views/TrainingContent";
import CompanyRegistration from "@/views/CompanyRegistration";
import Notifications from "@/views/Notifications";
import CompanyLogo from "@/views/CompanyLogo";
import Company from "@/views/Company";
import EditCompany from "@/views/EditCompany";
import ForgotPassword from "@/views/ForgotPassword";
import UpdatePassword from "@/views/UpdatePassword";
import Profile from "@/views/MyProfile";
import EditProfile from "@/views/EditProfile";
import VerifyAccount from "@/views/VerifyAccount";
import NotFound from "@/components/NotFound";
import BusinessInfo from "@/views/BusinessInfo";
import RegisterOnboarding from "@/views/RegisterOnboarding";
import Product from "@/views/Product";
import EditProduct from "@/views/EditProduct";
import EditProductOrPricing from "@/views/EditProductOrPricing";
import ProductPricingUpdate from "@/views/ProductPricingUpdate";
import InterestsDashboard from "@/views/InterestsDashboard";
import ProductImagesView from "@/views/ProductImagesView";
import { store } from "@/store";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: __dirname,
  routes: [
    {
      path: "/login",
      component: Login,
      name: "Login",
      meta: {
        guest: true
      }
    },
    {
      path: "/verify",
      component: VerifyAccount,
      name: "VerifyAccount",
      meta: {
        guest: true
      }
    },
    {
      path: "/register",
      component: Register,
      name: "Register",
      meta: {
        guest: true
      }
    },
    {
      path: "/forgot-password",
      component: ForgotPassword,
      name: "forgotPassword",
      meta: {
        guest: true
      }
    },
    {
      path: "/password-update",
      component: UpdatePassword,
      name: "UpdatePassword",
      meta: {
        guest: true
      }
    },
    {
      path: "/",
      component: Home,
      name: "Home"
    },
    {
      path: "/faqs",
      component: FAQs,
      name: "FAQs"
    },
    {
      path: "/dashboard",
      component: Dashboard,
      name: "Dashboard"
    },
    {
      path: "/profile",
      component: Profile,
      name: "Profile",
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/profile/edit",
      component: EditProfile,
      name: "EditProfile",
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/company-registration",
      component: CompanyRegistration,
      name: "CompanyRegistration",
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/company-logo",
      component: CompanyLogo,
      name: "CompanyLogo",
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/notifications",
      component: Notifications,
      name: "Notifications",
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/interests",
      component: Interests,
      name: "Interests",
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/training",
      component: Training,
      name: "Training"
    },
    {
      path: "/training/:id",
      component: TrainingContent,
      name: "TrainingContent"
    },
    {
      path: "/interest",
      component: Interest,
      name: "Interest",
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/company/:id",
      component: Company,
      name: "Company",
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/company/:id/edit",
      component: EditCompany,
      name: "EditCompany",
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/products/:id",
      component: UploadProduct,
      name: "UploadProduct",
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/product/:id",
      component: Product,
      name: "Product",
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/product/:id/delete",
      component: EditProduct,
      name: "EditProduct",
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/product/:id/edit",
      component: EditProductOrPricing,
      name: "EditProductOrPricing",
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/product/:productId/pricing/:pricingId",
      component: ProductPricingUpdate,
      name: "ProductPricingUpdate",
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/products/:companyId/pricing/:productId",
      component: ProductPricing,
      name: "ProductPricing",
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/products/:id/upload",
      component: ProductImageUpload,
      name: "ProductImageUpload",
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/products/:id/images",
      component: ProductImagesView,
      name: "ProductImagesView",
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/interests-dashboard",
      component: InterestsDashboard,
      name: "InterestsDashboard",
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/why-business-info",
      component: BusinessInfo,
      name: "BusinessInfo",
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/register-onboarding",
      component: RegisterOnboarding,
      name: "RegisterOnboarding",
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/logout",
      beforeEnter() {
        store.dispatch("logout");
        router.push("/login");
      }
    },
    {
      path: "*",
      component: NotFound,
      name: "NotFound"
    }
  ]
});

router.beforeEach((to, _from, next) => {
  if (to.meta.requiresAuth) {
    if (!store.getters.isLoggedIn) {
      next({ name: "Login" });
    } else {
      next();
    }
  } else if (to.meta.guest) {
    if (store.getters.isLoggedIn) {
      next({ name: "Dashboard" });
    } else {
      next();
    }
  } else {
    next();
  }
});
router.afterEach(() => {
  window._paq.push(["trackPageView"]);
});

export default router;
