<template class="register-page">
  <Card displayControls>
    <ValidationObserver
      ref="obs"
      v-slot="{ invalid }"
      class="main-container d-flex flex-column justify-content-between"
    >
      <div class="px-7 pt-7">
        <h1 class="midnightGreen--text">
          Company
          <br />Info
        </h1>
        <v-form class="offset-x my-10">
          <ValidationProvider
            name="Company name"
            mode="eager"
            rules="required|min:3"
            v-slot="{ errors }"
          >
            <v-text-field
              class="input"
              type="text"
              id="company_name"
              v-model="form.company_name"
              :error-messages="errors"
              label="Company Name"
              required
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider
            name="Company type"
            rules="required"
            mode="eager"
            v-slot="{ errors }"
          >
            <v-select
              class="input"
              type="text"
              id="company_type"
              v-model="form.company_type"
              :error-messages="errors"
              :items="items"
              label="Company Type"
              required
            ></v-select>
          </ValidationProvider>
          <ValidationProvider
            name="Company Email"
            mode="eager"
            rules="required|email"
            v-slot="{ errors }"
          >
            <v-text-field
              class="input"
              type="text"
              v-model="form.company_email"
              :error-messages="errors"
              label="Company Email"
              required
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider
            name="Business registration number"
            mode="eager"
            rules="min:5"
            v-slot="{ errors }"
          >
            <v-text-field
              class="input"
              type="text"
              v-model="form.company_registration"
              :error-messages="errors"
              label="Business Registration Number"
              required
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider
            name="Business KRA Pin"
            mode="eager"
            rules="min:5"
            v-slot="{ errors }"
          >
            <v-text-field
              class="input"
              type="text"
              v-model="form.company_kra"
              :error-messages="errors"
              label="Business KRA Pin"
              required
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider
            name="Director's mobile number"
            :rules="{ required: true, regex: /^07\d{8}$/ }"
            mode="eager"
            v-slot="{ errors }"
          >
            <v-text-field
              class="input"
              type="text"
              :maxLength="10"
              v-model="form.phone_number"
              :error-messages="errors"
              label="Director's Mobile Number (07XXXXXXXX)"
              required
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider
            name="Company address"
            rules="required|min:5"
            mode="eager"
            v-slot="{ errors }"
          >
            <v-text-field
              class="input"
              type="text"
              v-model="form.company_address"
              :error-messages="errors"
              label="Company Address"
              required
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider
            name="Company location"
            rules="required|min:4"
            v-slot="{ errors }"
          >
            <v-text-field
              class="input"
              type="text"
              v-model="form.company_location"
              :error-messages="errors"
              label="Company Location"
              required
            ></v-text-field>
          </ValidationProvider>
          <div
            class="midnightGreen--text text-body-1 text-decoration-underline"
          >
            <router-link class="router-link" to="/why-business-info"
              >Why do we need this information?</router-link
            >
          </div>
        </v-form>
      </div>
      <div>
        <CompanyCreationNotice v-slot="{ attrs, on }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            tile
            class="midnightGreen--text py-10 text-h6 text-capitalize font-weight-bold rounded-corner"
            :width="buttonWidth"
            large
            color="#F8B234"
            :disabled="invalid"
          >
            Submit
          </v-btn>
        </CompanyCreationNotice>
      </div>
    </ValidationObserver>
  </Card>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Card from "@/components/Card";
import CompanyCreationNotice from "@/components/CompanyCreationNotice";

export default {
  name: "CompanyRegistration",
  components: {
    Card,
    CompanyCreationNotice
  },
  data() {
    return {
      items: ["Limited Company", "Limited Liability Partnership", "Business"]
    };
  },
  computed: {
    ...mapState(["form"]),
    ...mapGetters(["isLoading", "isNext"]),
    buttonWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "sm":
        case "xs":
          return "100%";
        default:
          return "35%";
      }
    }
  }
};
</script>

<style scoped>
.main-container {
  height: 100%;
}

.rounded-corner {
  border-top-right-radius: 30px;
}
</style>
