import Vue from "vue";
import Vuetify from "vuetify/lib";
import "material-design-icons-iconfont/dist/material-design-icons.css";

Vue.use(Vuetify);

const vuetify = new Vuetify({
  icons: {
    iconfont: "md"
  },
  theme: {
    themes: {
      light: {
        primary: "#fabc2a",
        secondary: "#0C363D",
        anchor: "#8c9eff",
        tertiary: "#FFFCF1",
        midnightGreen: "#0C363D",
        blueGreen: "#008f8f",
        darkBlue: "#2B2D42",
        lightGray: "#CCDADF",
        neutral: "#FAFCFD",
        secondaryNeutral: "#E2E9EB",
        lightGreen: "#EBF6F5"
      }
    }
  }
});

export default vuetify;
