<template>
  <v-main class="white overflow-y-auto">
    <Banner>
      <MobileNavbar />
      <v-container>
        <v-img class="jukua" src="@/assets/logo beta.svg" width="17vh" />
        <v-row no-gutters :class="height">
          <v-col sm="6">
            <v-spacer></v-spacer>
            <v-card-text class="pa-0 text-h4">
              Have Questions?
            </v-card-text>
            <v-card-text class="pa-0 pb-2 text-h4 font-weight-bold"
              >We have
              <span class="blueGreen--text">answers!</span></v-card-text
            >
            <hr class="horizontal-rule" />
            <v-card-text class="px-0 pb-3">
              We have shared some of the frequently asked questions to help you
              out. You can also search for a specific question.
            </v-card-text>
            <v-text-field
              v-model="searchQuery"
              rounded
              filled
              label="Search"
              type="text"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </Banner>
    <section class="pb-10">
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-card-text class="blueGreen--text mb-2 pa-0 text-h6">
              FAQs
            </v-card-text>
            <v-card-text
              class="font-weight-bold midnightGreen--text text-h5 pa-0 pb-2"
            >
              Browse all questions
            </v-card-text>
            <hr class="horizontal-rule" />
          </v-col>
          <v-col>
            <v-expansion-panels flat v-if="filteredList.length">
              <v-expansion-panel
                v-for="faq in filteredList"
                :key="faq.title"
                class="mb-5 lightGreen rounded-0 rounded-tr-xl"
              >
                <v-expansion-panel-header
                  expand-icon="mdi-menu-down"
                  class="h5 midnightGreen--text font-weight-bold"
                >
                  {{ faq.title }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <p v-html="faq.answer"></p>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-card-text
              class="midnightGreen--text font-weight-bold text--secondary text-center text-h5"
              v-else
            >
              No Results Found
            </v-card-text>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <Footer />
  </v-main>
</template>

<script>
import Banner from "@/components/Banner";
import MobileNavbar from "@/components/MobileNavbar";
import Footer from "@/components/Footer";

export default {
  name: "FAQs",
  components: {
    Banner,
    MobileNavbar,
    Footer
  },
  data: () => ({
    searchQuery: "",
    faqs: [
      {
        title: "Why should I register with Jukua platform?",
        answer: `<ul><li>It's FREE</li>
	                               Registration for our basic services such as product and supplier search and inquiry, are free of charge. There are no hidden costs.
	                               <li>Connect to multiple buyers and suppliers</li>
	                               Grow your network, buy and sell products or services and trade with confidence
	                               <li>Get access to training and events</li>
	                               Grow your business through industry-specific knowledge sharing and training on emerging trends.
	                               <li>Access To Directory</li>
	                               To provide a verified two-sided marketplace to extend MSMEs ability to find and to be found by verified and reliable supplier and customer bases.
	                               <li>Get free e-mail updates</li>
	                               Receive customizable updates about products and suppliers newly posted on the jukua platform, latest supply-industry news, latest events, all based on the product categories you selected.
	                               <li>Send inquiries direct to suppliers</li>
	                               Inquire about products you're interested in using a convenient inquiry form, send multiple inquiries at the same time, and use your updateable profile so the system can pre-complete inquiry forms with your company and contact information.
	                               <li>Personalize your Inquiry Basket</li>
	                               Your Inquiry Basket remembers the products and suppliers that you have stored, even if you leave the site and come back later.
	                               </ul>
	                               Register Now and start to benefit from jukua right away!`
      },
      {
        title: "What do I need to register?",
        answer:
          "To register for free, all you need is a valid e-mail address and password, plus your name and your business name. It’s that simple!"
      },
      {
        title: "How do I register?",
        answer:
          "Registration to Jukua is simple and fast:<ol><li>Click on the Register link at the top navigation bar on jukua.co.ke</li><li>Complete the required fields.</li><li>If you wish, enter one or more keywords to receive e-mail updates when new products are posted online, then click Continue. On the next page, you'll see matching categories to select for your Product Alert e-mail updates (free with your registration). Select your categories and click “Subscribe Now”. You will then see a confirmation page. Check your e-mail inbox afterwards and click the verification link in the e-mail we send you in order to confirm your Product Alert request.</li><li>If you don't want to enter any keywords, simply click Continue. On the next page, you will again have the option to enter keywords to select categories for free Product Alert e-mail updates.</li></ol>"
      },
      {
        title:
          "What do you do with my registration information?(Privacy Policy)",
        answer:
          "Jukua will never give out or sell your personal information to other parties. For more information, please read our <a href='#'>Privacy Policy</a>."
      },
      {
        title: "What are the guidelines for choosing a password?",
        answer:
          "Your Jukua password is case-sensitive. Here are a few guidelines for creating passwords: <ul><li>Use at least eight characters.</li> <li>Make a password easy for you to remember but hard for someone else to guess.</li> <li>Avoid passwords closely related to your personal information. For example, don't use your login name or company name.</li> <li>Use a mixture of letters or numbers and the punctuation marks (. _ -) — but don't use spaces.</li></ul>"
      },
      {
        title: "What if I change my mind?",
        answer:
          "You can log out of the website at any time by clicking the 'Logout' link at the top right-hand corner. After logging out, you can login again and choose whether to let your browser remember you."
      },
      {
        title: "Once I'm registered, what happens next?",
        answer:
          "After registration, you are automatically logged in to jukua platform and are able to use a much wider range of our services. You will also receive a Welcome e-mail from us, with helpful hints on how to make the most of the platform. Important: After registering, we recommend that you go to your User Profile page and complete your company information. This will let our system pre-complete an inquiry form with your details before you send it to suppliers Providing more details will also help suppliers reply to your requests appropriately. Such details include KRA pin, registration certificate etc. To do this make sure you are logged into the site. The User Profile link will only appear in the top navigation bar after a successful login."
      },
      {
        title: "Why should I select product categories?",
        answer:
          "Selecting product categories allows you to use jukua Product Alert, a customizable e-mail service that updates you on products, suppliers and industry news – in the categories you've selected – as soon as they're posted on the platform. You can add or remove categories, or stop receiving Product Alert updates, at any time."
      },
      {
        title: "How do I login?",
        answer:
          "Click the login link at the top right-hand corner of the page to go to the Login page. Enter your e-mail address and password and click on Login Now button. If you check the Remember Me box when you log in, and if you do not log out, you won't have to log in each time you come back. Your browser will 'remember' your e-mail address on your computer and your contact details will be pre-completed automatically when you send inquiries. However, for security reasons, you will always be asked to log in when you access secure parts of the site."
      },
      {
        title: "Why do I get an error message when I type in my password?",
        answer: `
	                   <ol>
	                       <li>Check how you are entering your password. Remember that passwords for jukua are case-sensitive. For example, if you entered 'abcDE5Z' when you originally registered, the site will only accept 'abcDE5Z' not 'abcde5z' or 'ABCDE5Z'.</li>
	                       <li>Check to see if your keyboard CAPS LOCK has been accidentally turned on. If so, it means your password will have been typed in the wrong case.</li>
	                   </ol>`
      },
      {
        title: "What if I forgot my password?",
        answer: `
	                   <ol>
	                       <li>Simply click on the "Forgot Password” link on the Login page.</li>
	                       <li>Enter your registered e-mail address.</li>
	                       <li>Click Submit to complete the process. Jukua will send an e-mail to your registered e-mail address. Follow the instructions in the e-mail to change your password. After you've changed it, you'll be able to login to the platform again. If you can't remember your registered e-mail address, please contact us.</li>
	                   </ol>`
      },
      {
        title: "How do I change my password?",
        answer: `
	                   <ol>
	                       <li>Once logged in, click on the User Profile link on the top-right hand corner.</li>
	                       <li>Click Change Password option under User Profile.</li>
	                       <li>Enter your current password and your new password. Re-enter your new password to confirm and click Submit.</li>
	                   </ol>`
      },
      {
        title: "Why is my account disabled?",
        answer: `
	                   We jukua accounts that don't follow the Terms. Some examples include:
	                   <ol>
	                       <li>Malware, phishing & other harmful activities</li>
	                       <li>Using a fake user profile.</li>
	                       <li>Account hacking or hijacking</li>
	                       <li>Spamming</li>
	                       <li>Creating a false identity to deceive people</li>
	                       <li>Terrorist content</li>
	                       <li>Automatic calls or messages</li>
	                       <li>Contacting other people for the purpose of harassment, advertising, promoting, or other conduct that's not allowed.</li>
	                   </ol>
	                   `
      },
      {
        title: "What can I do if my account has been disabled?",
        answer: `
	                   If you think your account has been disabled by accident, please log in and use the 'Reactivate Your Account' form to submit your appeal.
	                   <ul>
	                       <li>After you submit the form, we will review your request and process it within 2 business days. If your account has been unlocked, you will receive an email from Jukua saying that your account has been unlocked and is now fully accessible.
	                       <li>Please keep in mind that in some cases, we may not issue a warning before disabling an account. We cannot restore accounts that were disabled for severe violations of the Jukua Terms Of Use
	                   </ul>`
      },
      {
        title: "How do I update my User Profile?",
        answer: `
	                   <ol>
	                       <li>After you log in, click on the User Profile link at the top right-hand corner.</li>
	                       <li>On the User Profile screen, type in your new or updated information in the appropriate fields.</li>
	                       <li>Click Submit to save your changes.</li>
	                   </ol>
	                   `
      }
    ]
  }),
  computed: {
    filteredList() {
      return this.faqs.filter(
        faq =>
          faq.title.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          faq.answer.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
    height() {
      switch (this.$vuetify.breakpoint.name) {
        case "sm":
        case "xs":
          return "margin-sm";
        case "md":
          return "margin-md";
        default:
          return "margin-default";
      }
    }
  }
};
</script>

<style scoped>
.horizontal-rule {
  width: 63px;
  height: 3px;
  background: #ff5666;
}
</style>
