export const state = {
  user_uuid: null,
  next: false,
  alerts: null,
  token: null,
  verificationToken: null,
  expiresIn: null,
  isLoading: false,
  isSubmitted: false,
  productId: null,
  companyId: null,
  uploadUuid: "",
  imageUrl: "",
  isInterested: false,
  fileToUpload: null,
  previewImage: null,
  registered: false,
  oneTimeOnboarding: false,
  isEmpty: false,
  productImages: [],
  uploadImage: {
    categoryToUpdate: "",
    category: ""
  },
  profile: {},
  companies: [],
  searchedCompanies: [],
  myCompanies: [],
  mySearchedCompanies: [],
  company: null,
  interests: [],
  products: [],
  searchedProducts: [],
  categories: [],
  searchedCategories: [],
  trainingTags: [],
  trainingContent: [],
  trainingContentById: {
    title: null,
    html: null
  },
  companyProducts: [],
  companyDetails: {
    name: "",
    type: "",
    location: "",
    productId: "",
    companyId: "",
    companyLocation: "",
    companyName: "",
    companyType: "",
    logo: ""
  },
  form: {
    company_name: "",
    company_email: "",
    company_type: "",
    phone_number: "",
    company_address: "",
    company_location: "",
    company_registration: "",
    company_kra: ""
  },
  documents: {
    logo_uuid: "",
    kra_uuid: "",
    registration_uuid: "",
    license_uuid: ""
  },
  pricing: [],
  filteredTrainingContent: [],
  filteredTrainingContentChanged: false,
  filteredPreferences: [],
  preferenceTags: [],
  emptyPreferences: true,
  notifications: [],
  interestAndPricing: [],
  pages: {}
};
