<template>
  <v-btn
    :to="to"
    tile
    :class="
      `${
        textColor ? `${textColor}--text` : 'midnightGreen--text'
      } py-10 text-h6 text-capitalize font-weight-bold rounded-corner`
    "
    :width="buttonWidth"
    large
    :color="switchButtonColor"
    :disabled="invalid"
  >
    <slot />
  </v-btn>
</template>

<script>
export default {
  name: "BaseButton",
  props: {
    textColor: String,
    buttonColor: String,
    invalid: Boolean,
    to: [String, Object]
  },
  computed: {
    buttonWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "sm":
        case "xs":
          return "100%";
        default:
          return "35%";
      }
    },
    switchButtonColor() {
      return this.buttonColor ? this.buttonColor : "#F8B234";
    }
  }
};
</script>

<style scoped>
.rounded-corner {
  border-top-right-radius: 30px;
}
</style>
