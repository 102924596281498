<template>
  <Card
    classes="d-flex flex-column justify-space-between"
    displayControls
    title="Training Preferences"
  >
    <v-card flat class="pa-5">
      <v-card-title class="pa-0 text-h4 midnightGreen--text"
        >What would you like to learn?</v-card-title
      >
      <v-card-text class="pa-0 text-subtitle-1">
        Pick your interests and we will suggest courses we think you’ll love
      </v-card-text>
      <v-card flat class="d-flex flex-wrap">
        <span
          v-for="(tag, index) in trainingTags"
          :key="index"
          :class="{ highlight: selected.includes(tag.name) }"
          @click="
            selected.includes(tag.name)
              ? selected.splice(selected.indexOf(tag.name), 1)
              : selected.push(tag.name)
          "
        >
          <label class="text-capitalize" :for="index">{{ tag.name }}</label>
          <v-checkbox
            :id="tag.id"
            class="visually-hidden"
            v-model="selected"
            :value="tag.name"
          ></v-checkbox>
        </span>
      </v-card>
    </v-card>
    <BaseButton
      buttonColor="#35A19E"
      textColor="white"
      @click.native="selectedCourses(selected)"
      >Save</BaseButton
    >
  </Card>
</template>

<script>
import { mapState, mapActions } from "vuex";

import Card from "./Card";
import BaseButton from "./BaseButton";

export default {
  name: "SelectPreferencesTags",
  components: {
    Card,
    BaseButton
  },
  data() {
    return {
      selected: []
    };
  },
  computed: {
    ...mapState(["trainingTags"])
  },
  methods: {
    ...mapActions(["selectedCourses"])
  }
};
</script>

<style scoped>
.checkbox {
  opacity: 1;
}

label {
  background: #e2e9eb;
  padding: 0.5rem 1rem;
  border-radius: 2px 10px 2px 2px;
  cursor: pointer;
  color: #2b2d42;
  font-weight: bold;
  font-size: 19px;
}
.highlight label {
  background: #35a19e;
  color: white;
}

.visually-hidden {
  position: absolute;
  left: -100vw;
}
span {
  margin: 2%;
  margin-left: 0;
}
</style>
