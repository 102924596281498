import swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";

import router from "@/router";
import { jukuaApi } from "@/api/jukua";
import { ghostApi } from "@/api/ghost";
import { getCookie } from "@/api/csrf";
import { timeElapsed } from "@/utils/timeElapsed";
import * as mutations from "./mutation-types";

export const actions = {
  registration: async ({ commit, dispatch, state }, data) => {
    try {
      commit(mutations.setIsLoading);
      await getCookie();
      await jukuaApi.post("/auth/register", {
        name: data.name,
        email: data.email,
        password: data.password,
        id_passport_no: data.idPassport,
        url: `${process.env.VUE_APP_FRONTEND_URL}/verify`
      });

      commit(mutations.setIsLoading);
      commit(mutations.setRegistered);
      setTimeout(() => {
        router.push("/login");
        commit(mutations.setRegistered);
      }, 5000);
    } catch (error) {
      commit(mutations.setIsLoading);
      if (error.response.data.data.Response.data) {
        if (
          error.response.data.data.Response.data.email[0] ===
          "The email has already been taken."
        ) {
          commit(mutations.setAlert, "User already exists");
          dispatch("fireAlert", {
            alert: state.alerts,
            icon: "error"
          });
        }
      } else {
        commit(mutations.setAlert, "Failed registration");
        dispatch("fireAlert", {
          alert: state.alerts,
          icon: "error"
        });
      }
    }
  },
  login: async ({ commit, dispatch, state }, user) => {
    try {
      commit(mutations.setIsLoading);
      await getCookie();
      const response = await jukuaApi.post("/auth/login", {
        ...user
      });
      const token = response.data.data.Response.data.access_token;
      const expiresIn = response.data.data.Response.data.expires_in;
      commit(mutations.setIsLoading);
      commit(mutations.setToken, token);
      commit(mutations.setExpiration, expiresIn);

      router.push("/dashboard");
    } catch (error) {
      commit(mutations.setIsLoading);
      if (error.response.data.data.Response.data) {
        if (
          error.response.data.data.Response.data.email[0] ===
          "user email is not verified"
        ) {
          commit(mutations.setAlert, "Email is not verified");
          dispatch("fireAlert", {
            alert: state.alerts,
            icon: "error"
          });
        } else {
          commit(
            mutations.setAlert,
            "Login failed. Incorrect username or password"
          );
          dispatch("fireAlert", {
            alert: state.alerts,
            icon: "error"
          });
        }
      } else {
        commit(
          mutations.setAlert,
          "Login failed. Incorrect username or password"
        );
        dispatch("fireAlert", {
          alert: state.alerts,
          icon: "error"
        });
      }
    }
  },
  logout: ({ commit }) => {
    commit(mutations.logout);
  },
  fetchTokenFromUrl: ({ commit }) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    commit(mutations.setVerificationToken, urlParams.get("token"));
  },
  changePassword: async ({ commit, dispatch, state }, data) => {
    try {
      await jukuaApi.post("/auth/change-password", { ...data });
      commit(mutations.setAlert, "Password changed successfully");
      dispatch("fireAlert", { alert: state.alerts });
    } catch (error) {
      commit(mutations.setAlert, "Invalid password");
      dispatch("fireAlert", {
        alert: state.alerts,
        icon: "error"
      });
    }
  },
  resetPassword: async ({ commit, dispatch, state }, password) => {
    try {
      await jukuaApi.post("/auth/reset-password", {
        password,
        token: state.verificationToken
      });
      commit(mutations.setAlert, "Password changed successfully");
      dispatch("fireAlert", { alert: state.alerts });
      router.push("/login");
    } catch (error) {
      commit(mutations.setAlert, error.response.data.data.Response.message);
      dispatch("fireAlert", {
        alert: state.alerts,
        icon: "error"
      });
    }
  },
  forgotPassword: async ({ commit, dispatch, state }, data) => {
    try {
      await jukuaApi.post("/auth/forgot-password", data);
      commit(mutations.setIsSubmitted);
      setTimeout(() => {
        router.push("/login");
        commit(mutations.setIsSubmitted);
      }, 5000);
    } catch (error) {
      commit(mutations.setAlert, "Invalid email!");
      dispatch("fireAlert", {
        alert: state.alerts,
        icon: "error"
      });
    }
  },
  fetchProfile: async ({ commit, dispatch, state }) => {
    try {
      commit(mutations.setIsLoading);
      const response = await jukuaApi.get("/auth/me");
      commit(mutations.setProfile, response.data.data);
      commit(mutations.setIsLoading);
    } catch (error) {
      commit(mutations.setIsLoading);
      commit(mutations.setAlert, "Failed to load profile!");
      dispatch("fireAlert", {
        alert: state.alerts,
        icon: "error"
      });
    }
  },
  updateProfile: async ({ commit, dispatch, state }, data) => {
    try {
      commit(mutations.setIsLoading);
      await jukuaApi.put("/profile", {
        name: data.name,
        email: data.email_change,
        url: `${process.env.VUE_APP_FRONTEND_URL}/verify`
      });
      commit(mutations.setIsLoading);
      commit(
        mutations.setAlert,
        data.message ||
          "Success! Check your email for the verification link, and then sign in again"
      );
      dispatch("fireAlert", { alert: state.alerts });

      if (data.email_change) {
        dispatch("logout");
        router.push("/login");
      }
    } catch (error) {
      commit(mutations.setIsLoading);
      if (error.response.data.data.Response) {
        commit(mutations.setAlert, error.response.data.data.Response.message);
        dispatch("fireAlert", {
          alert: state.alerts,
          icon: "error"
        });
      } else {
        commit(mutations.setAlert, "Failed updating profile!");
        dispatch("fireAlert", {
          alert: state.alerts,
          icon: "error"
        });
      }
    }
  },
  deleteProfile: async ({ commit, dispatch, state }, password) => {
    try {
      commit(mutations.setIsLoading);
      await jukuaApi.delete("/profile", {
        data: password
      });
      commit(mutations.setAlert, "We are sad to see you go!");
      dispatch("fireAlert", { alert: state.alerts });
      router.push("/login");
      commit(mutations.logout);
      commit(mutations.setIsLoading);
    } catch (error) {
      commit(mutations.setIsLoading);
      commit(mutations.setAlert, "Failed deleting profile!");
      dispatch("fireAlert", {
        alert: state.alerts,
        icon: "error"
      });
    }
  },
  fetchCompanies: async (
    { commit, dispatch, state },
    { page: page = 1 } = { page: 1 }
  ) => {
    try {
      commit(mutations.setIsLoading);
      const response = await jukuaApi.get(`/companies?records=20&page=${page}`);
      const pages = {
        companies: {
          currentPage: response.data.meta.pagination.current_page,
          lastPage: response.data.meta.pagination.total_pages
        }
      };

      commit(mutations.setPages, pages);
      commit(mutations.setCompanies, response.data.data);
      commit(mutations.setIsLoading);
    } catch (error) {
      commit(mutations.setIsLoading);
      commit(mutations.setAlert, "Oops, something went wrong!");
      dispatch("fireAlert", { alert: state.alerts, icon: "error" });
    }
  },
  fetchMyCompanies: async ({ commit, dispatch, state }) => {
    try {
      commit(mutations.setIsLoading);
      const response = await jukuaApi(`/company-profile?records=30`);
      commit(mutations.setMyCompanies, response.data.data);
      commit(mutations.setIsLoading);
    } catch (error) {
      commit(mutations.setIsLoading);
      commit(mutations.setAlert, "Oops, something went wrong!");
      dispatch("fireAlert", { alert: state.alerts, icon: "error" });
    }
  },
  fetchCompanyProfile: async ({ commit }, id) => {
    try {
      commit(mutations.setIsLoading);
      const response = await jukuaApi(`/company-profile/${id}`);
      if (!response.data.data.logo) {
        let company = response.data.data;
        company.logo =
          "https://lwcoaching.com/wp-content/uploads/2014/04/Grey-background-plain.jpg";
        commit(mutations.setCompany, company);
      } else {
        commit(mutations.setCompany, response.data.data);
      }
      commit(mutations.setIsLoading);
    } catch (error) {
      commit(mutations.setIsLoading);
      commit(mutations.setCompany, {});
    }
  },
  fetchCompanyImageUrl: async ({ commit }, id) => {
    try {
      commit(mutations.setIsLoading);
      const response = await jukuaApi(`/company-documents/${id}`);

      let url;

      response.data.data.map(document => {
        if (document.upload_meta.description === "company logo picture") {
          url = document.url;
        }

        return document;
      });

      commit(mutations.setCompanyImageUrl, url);
      commit(mutations.setIsLoading);
    } catch (error) {
      commit(mutations.setIsLoading);
      commit(mutations.setCompanyImageUrl, "");
    }
  },
  fetchCompanyDocumentUuids: async ({ commit, dispatch, state }) => {
    try {
      const response = await jukuaApi.get("/settings/uploads?category=company");
      commit(mutations.setDocuments, {
        logo_uuid: response.data.data[0].uuid,
        kra_uuid: response.data.data[1].uuid,
        registration_uuid: response.data.data[2].uuid,
        license_uuid: response.data.data[3].uuid
      });
      commit(mutations.setUploadUuid, response.data.data[0].uuid);
    } catch (error) {
      commit(mutations.setAlert, "Oops, something went wrong!");
      dispatch("fireAlert", {
        alert: state.alerts,
        icon: "error"
      });
    }
  },
  fetchProfileImageUuid: async ({ commit }) => {
    const response = await jukuaApi.get("/settings/uploads?category=profile");
    const [profile] = response.data.data.filter(document => document.uuid);
    commit(mutations.setUploadUuid, profile.uuid);
  },
  updateCompany: async ({ commit, dispatch, state }, { id, formItems }) => {
    try {
      commit(mutations.setIsLoading);
      await jukuaApi.put(`/company-profile/${id}`, { ...formItems });
      commit(mutations.setIsLoading);
      commit(mutations.setAlert, "Saved successfully!");
      location.reload();
      dispatch("fireAlert", { alert: state.alerts });
    } catch (error) {
      commit(mutations.setAlert, "Update failed");
      dispatch("fireAlert", {
        alert: state.alerts,
        icon: "error"
      });
    }
  },
  fetchInterests: async ({ commit, dispatch, state }) => {
    try {
      const response = await jukuaApi.get("/my-interests");
      commit(mutations.setInterests, response.data.data);
    } catch (error) {
      commit(mutations.setAlert, "Oops, something went wrong!");
      dispatch("fireAlert", {
        alert: state.alerts,
        icon: "error"
      });
    }
  },
  fetchProducts: async (
    { commit, dispatch, state },
    { page: page = 1 } = { page: 1 }
  ) => {
    try {
      const response = await jukuaApi.get(`/products?records=20&page=${page}`);

      const data = response.data.data.map(product => product.category);
      const filteredCategories = [...new Set(data)];
      const categories = filteredCategories.map(category => ({
        name: category
      }));

      categories.unshift({ name: "All" });

      const pages = {
        products: {
          currentPage: response.data.meta.pagination.current_page,
          lastPage: response.data.meta.pagination.total_pages
        }
      };

      commit(mutations.setCategories, categories);
      commit(mutations.setPages, pages);
      commit(mutations.setProducts, response.data.data);
    } catch (error) {
      commit(mutations.setAlert, "Oops something went wrong!");
      dispatch("fireAlert", {
        alert: state.alerts,
        icon: "error"
      });
    }
  },
  fetchProduct: async ({ commit }, id) => {
    commit(mutations.setIsLoading);
    const images = [];

    const response = await jukuaApi.get(`/products/${id}`);

    if (!response.data.data.length) {
      commit(mutations.setIsEmpty, true);
      commit(mutations.setProductImages, response.data.data);
      commit(mutations.setIsLoading);
    } else {
      commit(mutations.setIsEmpty, false);

      response.data.data.map(product => {
        if (!product.images.length) {
          product.images.push({
            url: require("@/assets/no-company-logo.jpg")
          });
        }

        images.push({
          id: product.images[product.images.length - 1].id,
          productId: product.id,
          url: product.images[product.images.length - 1].url,
          name: product.name,
          category: product.category,
          pricing: product.pricing
        });
      });

      commit(mutations.setProductImages, images);
      commit(mutations.setIsLoading);
    }
  },
  fetchSingleProduct: async ({ commit, dispatch, state }, data) => {
    try {
      const response = await jukuaApi.get(`/products/${data.company}`, {
        params: {
          product_id: data.product
        }
      });

      const singleProduct = response.data.data;

      const pricing = [];
      const images = [];

      const companyDetails = {
        name: singleProduct.name,
        location: singleProduct.location,
        productId: singleProduct.id,
        unit: singleProduct.unit,
        companyId: singleProduct.company_id,
        logo: singleProduct.images.length
          ? singleProduct.images[singleProduct.images.length - 1].url
          : require("@/assets/no-company-logo.jpg"),
        companyName: singleProduct.company.name
      };

      commit(mutations.setCompanyDetails, companyDetails);

      const interestAndPricing = [];

      if (singleProduct.interests.length) {
        singleProduct.interests.map(interest => {
          const interests = {};

          const pricePoint = singleProduct.pricing.find(
            price => price.id === interest.price_point_id
          );

          interests.id = interest.id;
          interests.companyId = singleProduct.company_id;
          interests.companyName = interest.user.companies.length
            ? interest.user.companies[0].name
            : "Anonymous";
          interests.productId = interest.product_id;
          interests.productName = singleProduct.name;
          interests.productQuantity = singleProduct.quantity;
          interests.physicalLocation = interest.user.companies.length
            ? interest.user.companies[0].physical_location
            : "Nairobi";
          interests.category = singleProduct.category;
          interests.deliveryCost = pricePoint.delivery_cost;
          interests.minUnits = pricePoint.min_units;
          interests.unit = singleProduct.unit;
          interests.unitPrice = pricePoint.unit_price;
          interests.status = interest.status;
          interests.images = interest.user.companies.length
            ? interest.user.companies[0].logo
              ? interest.user.companies[0].logo.url
              : null
            : require("@/assets/no-company-logo.jpg");

          interestAndPricing.push(interests);
        });
      }

      const defaultLogo = {
        url: require("@/assets/no-company-logo.jpg")
      };

      if (singleProduct.pricing.length) {
        singleProduct.pricing.map(price => {
          pricing.push(price);
        });
      }

      if (!singleProduct.images.length) {
        singleProduct.images[0] = defaultLogo;
      } else {
        singleProduct.images.map(image => {
          images.push({
            id: image.id,
            url: image.url
          });
        });

        images.sort((current, prev) => prev.id - current.id);
      }

      commit(mutations.setCompanyProducts, singleProduct);
      commit(mutations.setInterestAndPricing, interestAndPricing);
      commit(mutations.setPricing, pricing);
      commit(mutations.setProductImages, images);
    } catch (error) {
      commit(mutations.setAlert, "Product not found!");
      dispatch("fireAlert", {
        alert: state.alerts,
        icon: "error"
      });
    }
  },
  updateProduct: async ({ commit, dispatch, state }, { companyId, data }) => {
    try {
      await jukuaApi.put(`/products/${companyId}`, { ...data });
      commit(mutations.setAlert, "Your Product was saved Successfully!");
      dispatch("fireAlert", { alert: state.alerts });
      router.push(`/company/${companyId}`);
    } catch (error) {
      commit(mutations.setAlert, "Product update not saved!");
      dispatch("fireAlert", {
        alert: state.alerts,
        icon: "error"
      });
    }
  },
  addPricing: async (
    { commit, dispatch, state },
    { id, company, route, data }
  ) => {
    try {
      commit(mutations.setIsLoading);
      const range = `${data.from_price} KES to ${data.to_price} KES`;
      await jukuaApi.post(`/products/${id}/pricing`, {
        unit_price: range,
        min_units: data.min_units,
        delivery_cost: data.delivery_cost
      });
      commit(mutations.setAlert, "Saved Successfully!");
      dispatch("fireAlert", { alert: state.alerts });
      commit(mutations.setIsLoading);

      if (route) {
        router.push(route);
      } else {
        router.push(`/products/${company}/upload?product=${id}`);
      }
    } catch (error) {
      commit(mutations.setIsLoading);
      commit(mutations.setAlert, "Error adding pricing!");
      dispatch("fireAlert", {
        alert: state.alerts,
        icon: "error"
      });
    }
  },
  updatePricing: async (
    { commit, dispatch, state },
    { id, companyId, data }
  ) => {
    try {
      commit(mutations.setIsLoading);
      await jukuaApi.put(`/products/${id}/pricing`, { ...data });
      commit(mutations.setAlert, "Updated Successfully!");
      dispatch("fireAlert", { alert: state.alerts });
      commit(mutations.setIsLoading);
      router.push(`/product/${id}?company=${companyId}`);
    } catch (error) {
      commit(mutations.setIsLoading);
      commit(mutations.setAlert, "Error updating pricing!");
      dispatch("fireAlert", {
        alert: state.alerts,
        icon: "error"
      });
    }
  },
  expressInterest: async ({ commit, dispatch, state }, id) => {
    try {
      if (state.myCompanies.length) {
        commit(mutations.setIsLoading);
        await jukuaApi.post(`/express-interest/${id}`);
        commit(mutations.setIsLoading);
        commit(mutations.setIsInterested);
      } else {
        commit(
          mutations.setAlert,
          "You don't have a company yet. Register one with us to continue."
        );
        dispatch("fireAlert", {
          alert: state.alerts,
          icon: "error"
        });
      }
    } catch (error) {
      commit(mutations.setIsLoading);
      commit(mutations.setAlert, "Error expressing interest!");
      dispatch("fireAlert", {
        alert: state.alerts,
        icon: "error"
      });
    }
  },
  acceptInterest: async ({ commit, dispatch, state }, id) => {
    try {
      await jukuaApi.put(`/accept-interest/${id}`);
      commit(mutations.setAlert, "You have accepted this request!");
      dispatch("fireAlert", { alert: state.alerts });
      location.reload();
    } catch (error) {
      commit(mutations.setAlert, "Error accepting request!");
      dispatch("fireAlert", {
        alert: state.alerts,
        icon: "error"
      });
    }
  },
  declineInterest: async ({ commit, dispatch, state }, id) => {
    try {
      await jukuaApi.put(`/decline-interest/${id}`);
      commit(mutations.setAlert, "You have declined this request!");
      dispatch("fireAlert", { alert: state.alerts });
      location.reload();
    } catch (error) {
      commit(mutations.setAlert, "Error declining request!");
      dispatch("fireAlert", {
        alert: state.alerts,
        icon: "error"
      });
    }
  },
  fireAlert: async (
    { commit },
    { alert = "Something went wrong!", icon = "success" }
  ) => {
    commit(mutations.setAlert, null);
    await swal.fire({
      text: alert,
      icon,
      showCloseButton: true
    });
  },
  confirmationAlert: async (
    { commit, dispatch },
    { company, product, price_point_id, sameRoute, propertyToDelete }
  ) => {
    commit(mutations.setAlert, null);
    const result = await swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    });

    if (result.value) {
      switch (propertyToDelete) {
        case "product":
          dispatch("deleteProduct", { company, product });
          await swal.fire(
            "Deleted!",
            `Your product has been deleted`,
            "success"
          );
          router.push(`/company/${company}`);
          break;
        case "company":
          dispatch("deleteCompanyProfile", company);
          await swal.fire(
            "Deleted!",
            `Company profile has been deleted`,
            "success"
          );
          router.push("/profile");
          break;
        case "pricing":
          dispatch("deletePricing", { product, price_point_id });
          await swal.fire(
            "Deleted!",
            `Product price point has been deleted`,
            "success"
          );
          sameRoute ? location.reload() : router.push("/profile");
      }
    }
  },
  verifyEmail: async ({ commit, dispatch, state }) => {
    try {
      await jukuaApi.post("/auth/verify-email", {
        token: state.verificationToken
      });
      commit(
        mutations.setAlert,
        "You have successfully registered with Jukua!"
      );
      dispatch("fireAlert", { alert: state.alerts });
      router.push("/login");
    } catch (error) {
      commit(mutations.setAlert, "Email verification failed!");
      dispatch("fireAlert", {
        alert: state.alerts,
        icon: "error"
      });
    }
  },
  fetchProductImageUuid: async ({ commit, dispatch, state }) => {
    try {
      const response = await jukuaApi.get("/settings/uploads?category=product");
      commit(mutations.setUploadUuid, response.data.data[0].uuid);
    } catch (error) {
      commit(mutations.setAlert, "Product setting error");
      dispatch("fireAlert", {
        alert: state.alerts,
        icon: "error"
      });
    }
  },
  addProduct: async ({ commit, dispatch, state }, { id, data }) => {
    try {
      commit(mutations.setIsLoading);
      const response = await jukuaApi.post(`/products/${id}`, { ...data });
      commit(mutations.setAlert, "Saved successfully!");
      dispatch("fireAlert", { alert: state.alerts });

      const productId = response.data.data.Response.data.product_id;

      commit(mutations.setIsLoading);
      router.push(`/products/${id}/pricing/${productId}`);
    } catch (error) {
      commit(mutations.setIsLoading);
      commit(mutations.setAlert, "Failed adding product");
      dispatch("fireAlert", {
        alert: state.alerts,
        icon: "error"
      });
    }
  },
  deleteProduct: async (_, { company, product }) => {
    await jukuaApi.delete(`/products/${company}?product_id=${product}`);
  },
  deleteCompanyProfile: async (_, company) => {
    await jukuaApi.delete(`/company-profile/${company}`);
  },
  deletePricing: async (_, { product, price_point_id }) => {
    await jukuaApi.delete(`/products/${product}/pricing`, {
      data: {
        price_point_id
      }
    });
  },
  uploadFiles: async (
    { commit, dispatch, state },
    { id, category, skipAlert = false, next = false }
  ) => {
    try {
      const formData = new FormData();
      formData.append(state.uploadUuid, state.fileToUpload);
      formData.append("category", category);
      formData.append("category_id", id);

      await jukuaApi.post("/uploads", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });

      if (!skipAlert) {
        commit(mutations.setAlert, "File uploaded successful!");
        await dispatch("fireAlert", { alert: state.alerts });
      }

      if (next) {
        commit(mutations.setNext);
        commit(mutations.setPreviewImage);
      } else {
        commit(mutations.setPreviewImage);
      }
    } catch (error) {
      if (error.response.status === 413) {
        commit(
          mutations.setAlert,
          "File too large, please use files smaller than 2MB!"
        );
        dispatch("fireAlert", {
          alert: state.alerts,
          icon: "error"
        });
      } else {
        commit(mutations.setAlert, "File upload failed!");
        dispatch("fireAlert", {
          alert: state.alerts,
          icon: "error"
        });
      }
    }
  },
  formatTrainingData: async (_, posts) => {
    const formattedData = await posts.map(post => {
      let formattedDate = new Date(post.published_at);
      post.published_at = formattedDate.toDateString();
      let postSnippet = post.excerpt.substring(0, 140);
      post.excerpt = `${postSnippet}...  Read more`;
      if (!post.feature_image) {
        post.feature_image =
          "https://images.freeimages.com/images/large-previews/864/mammatus-cloud-formation-1640460.jpg";
      }
      return post;
    });

    return formattedData;
  },
  filterByTag: async ({ commit, dispatch }, event) => {
    commit(mutations.setFilteredTrainingChangedToFalse);
    const tag = event.currentTarget.name.replace(/\s+/g, "-");
    const response = await ghostApi.get("/posts", {
      params: {
        filter: `tag:${tag}`,
        limit: 20
      }
    });

    commit(mutations.setFilteredTrainingChanged);

    const filteredTaining = await dispatch(
      "formatTrainingData",
      response.data.posts
    );

    commit(mutations.setFilteredTraining, filteredTaining);
  },
  fetchTags: async ({ commit }) => {
    const response = await ghostApi.get("/tags");

    const tags = response.data.tags;
    commit(mutations.setTags, tags);
  },
  filterByPreferences: async ({ commit, dispatch, state }) => {
    if (state.token) {
      commit(mutations.setIsLoading);
      commit(mutations.emptyPreferenceTags, true);
      const response = await jukuaApi.get(`/preference-tags`);

      if (response.data.data.length) {
        commit(mutations.emptyPreferenceTags, false);
      }

      commit(mutations.setPreferenceTags, response.data.data);

      const tags = [];

      response.data.data.map(data => {
        tags.push(data.slug);
      });

      let filter;

      if (tags.length) {
        filter = `tag:[${tags}]`;
      }

      commit(mutations.setIsLoading);

      const { data } = await ghostApi.get("/posts", {
        params: {
          filter,
          limit: 20
        }
      });

      const filteredTaining = await dispatch("formatTrainingData", data.posts);

      commit(mutations.setFilteredPreferences, filteredTaining);
    }
  },
  fetchTrainingContent: async (
    { commit, dispatch },
    { page: page = 1, records: records = 15 } = { page: 1, records: 15 }
  ) => {
    const response = await ghostApi.get("/posts", {
      params: {
        limit: records,
        page
      }
    });

    const pages = {
      training: {
        currentPage: response.data.meta.pagination.page,
        lastPage: response.data.meta.pagination.pages
      }
    };

    commit(mutations.setFilteredTrainingChangedToFalse);
    commit(mutations.setPages, pages);
    const trainingContent = await dispatch(
      "formatTrainingData",
      response.data.posts
    );

    commit(mutations.setTrainingContent, trainingContent);
  },

  fetchTrainingContentById: async ({ commit }, id) => {
    let response = await ghostApi.get("/posts");
    let [post] = response.data.posts.filter(post => post.id === id);

    while (!post && !!response.data.meta.pagination.next) {
      response = await ghostApi.get("/posts", {
        page: response.data.meta.pagination.next
      });
      [post] = response.data.posts.filter(post => post.id === id);
    }

    const { title, html } = post;
    commit(mutations.setTrainingContentById, { title, html });
  },

  createCompany: async ({ commit, dispatch, state }) => {
    try {
      commit(mutations.setIsLoading);

      const response = await jukuaApi.post("/company-profile", {
        name: state.form.company_name,
        email: state.form.company_email,
        type: state.form.company_type,
        phone_number: `+254${state.form.phone_number.substring(1)}`,
        address: state.form.company_address,
        physical_location: state.form.company_location,
        kra_pin: state.form.company_kra,
        business_regno: state.form.company_registration
      });

      const companyId = response.data.data.Response.data.id;

      commit(mutations.setRegistrationForm, {});
      commit(mutations.setIsLoading);
      commit(
        mutations.setAlert,
        "You have successfully created a company! Our team will need 24hrs to verify the details you have provided to us."
      );

      dispatch("fireAlert", { alert: state.alerts });

      router.push({
        path: "/company-logo",
        query: { company: companyId }
      });
    } catch (error) {
      commit(mutations.setIsLoading);
      commit(mutations.setAlert, "Error creating a company!");
      dispatch("fireAlert", {
        alert: state.alerts,
        icon: "error"
      });
    }
  },
  onFileSelected: ({ commit }, event) => {
    const file = event.target.files[0];
    commit(mutations.setFileToUpload, file);
    const url = URL.createObjectURL(file);
    commit(mutations.setPreviewImage, url);
  },
  selectedCourses: async ({ commit, dispatch, state }, preferences) => {
    try {
      await jukuaApi.post("/preference-tags", {
        tags: preferences
      });

      location.reload();
    } catch (error) {
      commit(mutations.setAlert, "Error with preferences");
      dispatch("fireAlert", {
        alert: state.alerts,
        icon: "error"
      });
    }
  },
  fetchNotifications: async ({ commit, dispatch, state }) => {
    try {
      commit(mutations.setIsLoading);
      const response = await jukuaApi.get("/notifications");
      const notificationResponse = response.data.data;

      const notifications = [];

      if (notificationResponse.length) {
        notificationResponse.forEach(notification => {
          const notif = {
            user_id: notification.user_id,
            user_uuid: notification.interest.user.user_uuid,
            interestId: notification.model_id,
            companyId: notification.interest.product.company_id,
            productId: notification.interest.product.id,
            notificationId: notification.id,
            productName: notification.interest.product.name,
            companyName: notification.interest.user.companies.length
              ? notification.interest.user.companies[0].name
              : "Anonymous",
            status: notification.status,
            message: notification.message,
            unit: notification.interest.product.unit,
            unitPrice: notification.interest.product_price.unit_price,
            minUnits: notification.interest.product_price.min_units,
            elapsedTime: timeElapsed(notification.updated_at),
            image: notification.interest.user.companies.length
              ? notification.interest.user.companies[0].logo
              : null
          };

          notifications.push(notif);
        });
      }

      commit(mutations.setNotifications, notifications);
      commit(mutations.setIsLoading);
    } catch (error) {
      commit(mutations.setIsLoading);
      commit(mutations.setAlert, "Error loading notifications");
      dispatch("fireAlert", { alert: state.alerts, icon: "error" });
    }
  },
  notificationRead: async ({ commit, dispatch, state }, notificationId) => {
    try {
      await jukuaApi.put(`/notifications/${notificationId}`, {
        status: "read"
      });
    } catch (error) {
      commit(mutations.setAlert, "Error with notification");
      dispatch("fireAlert", { alert: state.alerts, icon: "error" });
    }
  },
  setCurrentUserUuid: async ({ commit, dispatch, state }) => {
    commit(mutations.setIsLoading);
    await dispatch("fetchCompanies");
    const company = state.companies.find(company => company.mine === "Y");

    if (company) {
      commit(mutations.setUserUuid, company.user_uuid);
    }
    commit(mutations.setIsLoading);
  },
  searchProducts: async ({ commit, dispatch, state }, { query, page = 1 }) => {
    try {
      const response = await jukuaApi.get(
        `/products?s=${query}&records=20&page=${page}`
      );
      const data = response.data.data.map(product => product.category);
      const filteredCategories = [...new Set(data)];
      const categories = filteredCategories.map(category => ({
        name: category
      }));

      categories.unshift({ name: "All" });

      const pages = {
        searchedProducts: {
          currentPage: response.data.meta.pagination.current_page,
          lastPage: response.data.meta.pagination.total_pages
        }
      };

      commit(mutations.setSearchedCategories, categories);
      commit(mutations.setPages, pages);
      commit(mutations.setSearchedProducts, response.data.data);
    } catch (error) {
      commit(mutations.setAlert, "Error executing search!");
      dispatch("fireAlert", {
        alert: state.alerts,
        icon: "error"
      });
    }
  },
  searchCompanies: async ({ commit, dispatch, state }, { query, page = 1 }) => {
    try {
      const response = await jukuaApi.get(
        `/companies?s=${query}&records=20&page=${page}`
      );
      const pages = {
        searchedCompanies: {
          currentPage: response.data.meta.pagination.current_page,
          lastPage: response.data.meta.pagination.total_pages
        }
      };

      const myCompanies = response.data.data.filter(
        company => company.mine === "Y"
      );
      commit(mutations.setPages, pages);
      commit(mutations.setSearchedCompanies, response.data.data);
      commit(mutations.setMySearchedCompanies, myCompanies);
    } catch (error) {
      commit(mutations.setAlert, "Error executing search!");
      dispatch("fireAlert", { alert: state.alerts, icon: "error" });
    }
  }
};
