<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <slot :attrs="attrs" :on="on" />
    </template>
    <v-card class="p-3">
      <v-row>
        <v-col cols="12">
          <v-card-text
            v-if="!form.company_registration || !form.company_kra"
            class="midnightGreen--text font-weight-bold text-h6 text-center mt-4"
          >
            Business Registration Number and Business KRA Pin are both required
            to verify your business. To have your products displayed in the
            dashboard, please provide them.
          </v-card-text>
          <v-card-text
            v-else
            class="midnightGreen--text font-weight-bold text-h6 text-center mt-4"
          >
            We shall use Business Registration Number and Business KRA Pin for
            verification purposes.
          </v-card-text>
          <v-card-text
            class="red--text pb-10 font-weight-bold text-h6 text-center mt-4"
          >
            Are you sure you want to proceed?
          </v-card-text>
        </v-col>
        <v-col cols="6" class="text-center">
          <v-btn
            color="#35a19e"
            class="text-large font-weight-normal px-10 py-8"
            rounded
            large
            @click="(dialog = !dialog), createCompany()"
            dark
          >
            <v-icon>mdi-checkbox-marked-circle</v-icon>yes
          </v-btn>
        </v-col>
        <v-col cols="6" class="text-center">
          <v-btn
            color="#ff5666"
            class="text-large font-weight-normal px-10 py-8"
            rounded
            large
            @click="dialog = !dialog"
            dark
          >
            <v-icon>mdi-close</v-icon>No
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "CompanyCreationNotice",
  data() {
    return {
      dialog: false
    };
  },
  computed: mapState(["form"]),
  methods: mapActions(["createCompany"])
};
</script>
