var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Card',{attrs:{"displayControls":"","title":"Edit Product"}},[_c('ProductImage',{attrs:{"companyProducts":_vm.companyProducts,"editable":""}}),_c('ImageGrid',{attrs:{"productImages":_vm.productImages}}),_c('ValidationObserver',{ref:"observer",staticClass:"d-flex flex flex-column justify-space-between",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var changed = ref.changed;
return [(_vm.companyProducts)?_c('v-form',{staticClass:"px-10"},[_c('ValidationProvider',{attrs:{"name":"Product name","rules":"min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Product Name","error-messages":errors,"placeholder":_vm.companyProducts.name,"required":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Category","rules":"min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.categories,"label":"Category","error-messages":errors,"placeholder":_vm.companyProducts.category,"required":""},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}})]}}],null,true)}),(_vm.category === 'Other')?_c('ValidationProvider',{attrs:{"name":"Other Category","rules":"min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Other category","error-messages":errors,"type":"text"},model:{value:(_vm.category_other),callback:function ($$v) {_vm.category_other=$$v},expression:"category_other"}})]}}],null,true)}):_vm._e(),_c('ValidationProvider',{attrs:{"name":"Unit of measurement","mode":"eager","rules":"min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"input-field",attrs:{"type":"text","items":_vm.units,"error-messages":errors,"label":"Unit of Measurement","placeholder":_vm.companyProducts.unit,"required":""},model:{value:(_vm.unit),callback:function ($$v) {_vm.unit=$$v},expression:"unit"}})]}}],null,true)}),(_vm.unit === 'Other')?_c('ValidationProvider',{attrs:{"name":"Unit of measurement","rules":"min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input",attrs:{"label":"Other unit of measurement","error-messages":errors,"type":"text"},model:{value:(_vm.unit_other),callback:function ($$v) {_vm.unit_other=$$v},expression:"unit_other"}})]}}],null,true)}):_vm._e(),_c('ValidationProvider',{attrs:{"name":"Quantity","rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input",attrs:{"label":"Quantity","error-messages":errors,"placeholder":_vm.companyProducts.quantity,"type":"text"},model:{value:(_vm.quantity),callback:function ($$v) {_vm.quantity=_vm._n($$v)},expression:"quantity"}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Location of product","rules":"min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input",attrs:{"label":"Location of Product","error-messages":errors,"placeholder":_vm.companyProducts.location,"type":"text"},model:{value:(_vm.location),callback:function ($$v) {_vm.location=$$v},expression:"location"}})]}}],null,true)})],1):_vm._e(),_c('v-list',{staticClass:"px-6",attrs:{"three-line":"","nav":""}},_vm._l((_vm.getPricing),function(price){return _c('v-list-item-group',{key:price.id},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"router":"","to":{
                path: ("/product/" + (_vm.$route.params.id) + "/pricing/" + (price.id)),
                query: {
                  company: _vm.$route.query.company
                }
              }}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h6 font-weight-normal blueGreen--text"},[_vm._v(_vm._s(price.unit_price)+" ")]),_c('v-list-item-subtitle',[_vm._v(" per "+_vm._s(_vm._f("lowercase")(price.min_units))+" ● Min. Order "+_vm._s(price.min_units)+" ")]),_c('v-list-item-title',{staticClass:"text-subtitle-1"},[_vm._v(" "+_vm._s(price.delivery_cost))])],1),_c('v-icon',{attrs:{"fab":"","color":"#F8B234"}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit pricing")])]),_c('v-divider',{attrs:{"inset":""}})],1)}),1),_c('v-card',{staticClass:"d-flex align-items-center px-5 pb-5",attrs:{"flat":""}},[_c('v-icon',{attrs:{"size":"30","color":"#35A19E"}},[_vm._v("mdi-plus-circle")]),_c('span',{staticClass:"midnightGreen--text delete--button text-decoration-underline text-capitalize text-h6 font-weight-normal",on:{"click":function($event){return _vm.$router.push({
            path: ("/products/" + (_vm.$route.query.company) + "/pricing/" + (_vm.$route.params.id)),
            query: {
              route: ("/product/" + (_vm.$route.params.id) + "/edit?company=" + (_vm.$route.query.company))
            }
          })}}},[_vm._v("Add Another Product Price")])],1),_c('BaseButton',{attrs:{"buttonColor":"blueGreen","textColor":"white","invalid":_vm.isUpdated(invalid, changed)},nativeOn:{"click":function($event){return _vm.updateProduct({
          companyId: _vm.$route.query.company,
          data: {
            name: _vm.name || _vm.companyProducts.name,
            category: _vm.category_other
              ? _vm.category_other
              : _vm.category || _vm.companyProducts.category,
            unit: _vm.unit_other ? _vm.unit_other : _vm.unit || _vm.companyProducts.unit,
            quantity: _vm.quantity || _vm.companyProducts.quantity,
            location: _vm.location || _vm.companyProducts.location,
            product_id: _vm.$route.params.id
          }
        })}}},[_vm._v("Save")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }