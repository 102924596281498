export const setOneTimeOnboarding = "setOneTimeOnboarding";
export const setRegistered = "setRegistered";
export const setIsSubmitted = "setIsSubmitted";
export const setProfile = "setProfile";
export const setCompany = "setCompany";
export const setCompanies = "setCompanies";
export const setMyCompanies = "setMyCompanies";
export const setProducts = "setProducts";
export const setCompanyProducts = "setCompanyProducts";
export const setInterests = "setInterests";
export const setToken = "setToken";
export const setVerificationToken = "setVerificationToken";
export const setIsLoading = "setIsLoading";
export const setAlert = "setAlert";
export const logout = "logout";
export const setFileToUpload = "setFileToUpload";
export const setProductId = "setProductId";
export const setCompanyId = "setCompanyId";
export const setUploadUuid = "setUploadUuid";
export const setTrainingContent = "setTrainingContent";
export const setTrainingContentById = "setTrainingContentById";
export const setTags = "setTags";
export const setNext = "setNext";
export const setNextToFalse = "setNextToFalse";
export const setDocuments = "setDocuments";
export const setCompanyImageUrl = "setCompanyImageUrl";
export const setIsEmpty = "setIsEmpty";
export const setProductImages = "setProductImages";
export const setPreviewImage = "setPreviewImage";
export const setPricing = "setPricing";
export const setIsInterested = "setIsInterested";
export const setValueOfIsInterested = "setValueOfIsInterested";
export const setCompanyDetails = "setCompanyDetails";
export const setFilteredTraining = "setFilteredTraining";
export const setPreferenceTags = "setPreferenceTags";
export const emptyPreferenceTags = "emptyPreferenceTags";
export const setExpiration = "setExpiration";
export const setNotifications = "setNotifications";
export const setUserUuid = "setUserUuid";
export const setInterestAndPricing = "setInterestAndPricing";
export const setCategories = "setCategories";
export const setPages = "setPages";
export const setFilteredTrainingChanged = "setFilteredTrainingChanged";
export const setFilteredTrainingChangedToFalse =
  "setFilteredTrainingChangedToFalse";
export const setFilteredPreferences = "setFilteredPreferences";
export const setSearchedProducts = "setSearchedProducts";
export const setSearchedCategories = "setSearchedCategories";
export const setSearchedCompanies = "setSearchedCompanies";
export const setMySearchedCompanies = "setMySearchedCompanies";
export const setRegistrationForm = "setRegistrationForm";
