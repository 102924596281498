<template>
  <Card displayControls notifications>
    <ValidationObserver
      class="d-flex flex flex-column justify-space-between"
      ref="observer"
      v-slot="{ changed }"
    >
      <v-card flat class="px-7 pt-7">
        <h1 class="midnightGreen--text">
          Product Image
          <br />Upload
        </h1>
        <div class="pt-16 d-flex flex  justify-center">
          <ImageInput
            image
            imageSize="220"
            iconSize="150"
            iconPosition="top: 9rem; left: 12rem;"
            edgeIconSize="100"
          />
        </div>
      </v-card>
      <BaseButton
        @click.native="submitProduct"
        :invalid="isImageSelected(changed)"
        buttonColor="blueGreen"
        textColor="white"
        >Submit</BaseButton
      >
    </ValidationObserver>
  </Card>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";

import BaseButton from "@/components/BaseButton";
import Card from "@/components/Card";
import ImageInput from "@/components/ImageInput";

export default {
  name: "ProductImageUpload",
  components: {
    BaseButton,
    Card,
    ImageInput
  },
  created() {
    this.fetchProductImageUuid();
  },
  computed: {
    ...mapState(["previewImage", "productId", "next"]),
    ...mapGetters(["isNext"])
  },
  methods: {
    ...mapActions(["uploadFiles", "fetchProductImageUuid"]),
    ...mapMutations(["setNextToFalse"]),
    isImageSelected(changed) {
      if (this.previewImage) {
        return false;
      }

      if (changed) {
        return false;
      }

      return true;
    },
    async submitProduct() {
      await this.uploadFiles({
        id: this.$route.query.product,
        category: "product",
        next: true
      });

      if (this.isNext) {
        this.$router.push(
          `/products/${this.$route.params.id}/images?product=${this.$route.query.product}`
        );
        this.setNextToFalse();
      }
    }
  }
};
</script>
