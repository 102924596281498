<template>
  <v-list-item-group v-if="isPopulated">
    <div class="pt-10 d-flex flex-column align-center">
      <v-icon v-if="items" color="#FF5666" class="ma-2" size="100"
        >not_interested</v-icon
      >
      <v-icon v-else color="#FF5666" class="ma-2" size="100"
        >notifications_off</v-icon
      >
      <div class="midnightGreen--text font-weight-bold text-h6">
        <slot />
      </div>
    </div>
  </v-list-item-group>
</template>

<script>
export default {
  name: "NoItems",
  props: {
    isPopulated: Boolean,
    items: Boolean
  }
};
</script>
