export const mutations = {
  setOneTimeOnboarding: state => {
    state.oneTimeOnboarding = !state.oneTimeOnboarding;
  },
  setRegistered: state => {
    state.registered = !state.registered;
  },
  setIsSubmitted: state => {
    state.isSubmitted = !state.isSubmitted;
  },
  setProfile: (state, profile) => {
    state.profile = profile;
  },
  setCompany: (state, company) => {
    state.company = company;
  },
  setCompanies: (state, companies) => {
    state.companies = companies;
  },
  setMyCompanies: (state, myCompanies) => {
    state.myCompanies = myCompanies;
  },
  setProducts: (state, products) => {
    products.map(product => {
      if (product.images[0] === undefined) {
        product.images[0] = {
          url: "https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
        };
      }

      if (!product.pricing.length) {
        product.pricing = "none";
      }
    });

    state.products = products;
  },
  setCompanyProducts: (state, companyProducts) => {
    state.companyProducts = companyProducts;
  },
  setInterests: (state, interests) => {
    state.interests = interests;
  },
  setToken: (state, token) => {
    state.token = token;
  },
  setVerificationToken: (state, verificationToken) => {
    state.verificationToken = verificationToken;
  },
  setIsLoading: state => {
    state.isLoading = !state.isLoading;
  },
  setAlert: (state, alerts) => {
    state.alerts = alerts;
  },
  logout(state) {
    state.token = null;
  },
  setFileToUpload: (state, fileToUpload) => {
    state.fileToUpload = fileToUpload;
  },
  setProductId: (state, productId) => {
    state.productId = productId;
  },
  setCompanyId: (state, companyId) => {
    state.companyId = companyId;
  },
  setUploadUuid: (state, uploadUuid) => {
    state.uploadUuid = uploadUuid;
  },
  setTrainingContent: (state, trainingContent) => {
    state.trainingContent = trainingContent;
  },
  setTrainingContentById: (state, trainingContentById) => {
    state.trainingContentById = trainingContentById;
  },
  setTags: (state, trainingTags) => {
    state.trainingTags = trainingTags;
  },
  setNext: state => {
    state.next = !state.next;
  },
  setNextToFalse: state => {
    state.next = false;
  },
  setDocuments: (state, documents) => {
    state.documents = documents;
  },
  setCompanyImageUrl: (state, imageUrl) => {
    state.imageUrl = imageUrl;
  },
  setIsEmpty: state => {
    state.isEmpty = !state.isEmpty;
  },
  setProductImages: (state, productImages) => {
    state.productImages = productImages;
  },
  setPreviewImage: (state, previewImage) => {
    state.previewImage = previewImage;
  },
  setPricing: (state, pricing) => {
    state.pricing = pricing;
  },
  setIsInterested: state => {
    state.isInterested = !state.isInterested;
  },
  setValueOfIsInterested: (state, isInterested) => {
    state.isInterested = isInterested;
  },
  setCompanyDetails: (state, companyDetails) => {
    state.companyDetails = companyDetails;
  },
  setFilteredTraining: (state, filteredTrainingContent) => {
    state.filteredTrainingContent = filteredTrainingContent;
  },
  setFilteredPreferences: (state, filteredPreferences) => {
    state.filteredPreferences = filteredPreferences;
  },
  setFilteredTrainingChanged: state => {
    state.filteredTrainingContentChanged = !state.filteredTrainingContentChanged;
  },
  setFilteredTrainingChangedToFalse: state => {
    state.filteredTrainingContentChanged = false;
  },
  setPreferenceTags: (state, preferenceTags) => {
    state.preferenceTags = preferenceTags;
  },
  emptyPreferenceTags: (state, emptyPreferences) => {
    state.emptyPreferences = emptyPreferences;
  },
  setExpiration: (state, expiresIn) => {
    state.expiresIn = expiresIn;
  },
  setNotifications: (state, notifications) => {
    state.notifications = notifications;
  },
  setUserUuid: (state, user_uuid) => {
    state.user_uuid = user_uuid;
  },
  setInterestAndPricing: (state, interestAndPricing) => {
    state.interestAndPricing = interestAndPricing;
  },
  setCategories: (state, categories) => {
    state.categories = categories;
  },
  setPages: (state, pages) => {
    state.pages = { ...state.pages, ...pages };
  },
  setSearchedProducts: (state, searchedProducts) => {
    state.searchedProducts = searchedProducts;
  },
  setSearchedCategories: (state, searchedCategories) => {
    state.searchedCategories = searchedCategories;
  },
  setSearchedCompanies: (state, searchedCompanies) => {
    state.searchedCompanies = searchedCompanies;
  },
  setMySearchedCompanies: (state, mySearchedCompanies) => {
    state.mySearchedCompanies = mySearchedCompanies;
  },
  setRegistrationForm: (state, form) => {
    state.form = form;
  }
};
