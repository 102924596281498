<template>
  <v-col lg="6" cols="12">
    <v-dialog v-model="dialog" persistent max-width="600px" id="deleteProfile">
      <template v-slot:activator="{ on, attrs }">
        <slot :attrs="attrs" :on="on" />
      </template>
      <v-card class="p-3">
        <v-row>
          <v-col cols="12">
            <h1
              class="h2 pb-10 midnightGreen--text font-weight-bold text-center mt-4"
            >
              Are you sure you want to
              <br />delete your profile?
            </h1>
          </v-col>
          <v-col cols="6" class="text-center">
            <v-btn
              color="#35a19e"
              class="text-large font-weight-normal px-10 py-8"
              rounded
              large
              @click="yesDelete = !yesDelete"
              dark
              v-if="!yesDelete"
            >
              <v-icon>mdi-checkbox-marked-circle</v-icon>yes
            </v-btn>
          </v-col>
          <v-col cols="6" class="text-center">
            <v-btn
              color="#ff5666"
              class="text-large font-weight-normal px-10 py-8"
              rounded
              large
              @click="(dialog = !dialog), (yesDelete = false)"
              dark
              v-if="!yesDelete"
            >
              <v-icon>mdi-close</v-icon>No
            </v-btn>
          </v-col>
        </v-row>
        <v-card-text>
          <v-container>
            <v-row class="mx-2">
              <v-col cols="12" class="my-4" v-if="yesDelete">
                <ValidationProvider
                  rules="required|min:5|max:36|confirmed:confirm"
                  name="Password"
                  vid="password"
                >
                  <div slot-scope="{ errors, failedRules }" label="Password">
                    <v-text-field
                      v-model="password"
                      class="my-1"
                      label="Password"
                      type="password"
                      :error-messages="errors"
                      required
                    ></v-text-field>
                    <span class="span" v-if="failedRules.min"
                      >Your password must be atleast 5 characters long.</span
                    >
                  </div>
                </ValidationProvider>
                <ValidationProvider
                  vid="confirm"
                  rules="required"
                  name="Confirm Password"
                >
                  <div slot-scope="{ errors, failedRules }" label="Password">
                    <v-text-field
                      v-model="passwordConfirmed"
                      class="my-1"
                      label="Confirm Password"
                      type="password"
                      :error-messages="errors"
                      required
                    ></v-text-field>
                    <span class="span" v-if="failedRules.confirmed"
                      >Your passwords do not match.</span
                    >
                  </div>
                </ValidationProvider>
                <v-btn
                  @click="deleteProfile({ password })"
                  color="#ff5666"
                  class="text-center"
                  dark
                  rounded
                >
                  <v-icon>mdi-close</v-icon>Delete
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            color="blue darken-1"
            text
            @click="(dialog = !dialog), (yesDelete = false)"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "DeleteProfile",
  data() {
    return {
      password: "",
      passwordConfirmed: "",
      dialog: false,
      yesDelete: false
    };
  },
  methods: {
    ...mapActions(["deleteProfile"])
  }
};
</script>
