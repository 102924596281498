<template>
  <Card displayControls title="Interests Dashboard" interests>
    <v-list class="px-6" three-line>
      <NoItems
        v-if="!interestAndPricing.length"
        :isPopulated="!interestAndPricing.length"
        items
      >
        No Interests
      </NoItems>
      <v-list-item-group v-else>
        <v-list-item
          v-for="interest in interestAndPricing"
          :key="interest.id"
          :ripple="false"
          inactive
        >
          <v-list-item-content color="white">
            <v-list-item inactive disbled class="d-flex pa-0">
              <v-list-item-avatar
                tile
                size="120"
                color="grey lighten-4"
                class="rounded-tr-xl"
              >
                <v-img v-if="interest.images" :src="interest.images"></v-img>
                <v-icon color="midnightGreen" size="80" v-else
                  >account_balance</v-icon
                >
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title color="#2B2D42" class="text-h6">
                  {{ interest.companyName }}
                  <v-list-item-subtitle class="pt-4">
                    {{ interest.physicalLocation }}
                  </v-list-item-subtitle>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item-title class="text-h6 font-weight-normal"
              >{{ interest.productName }}
            </v-list-item-title>
            <v-list-item-title
              class="text-h6 font-weight-normal blueGreen--text"
              >{{ interest.unitPrice }}
            </v-list-item-title>
            <v-list-item-subtitle>
              per {{ interest.unit | lowercase }} &#9679; Min Order Qty
              {{ interest.productQuantity }}
            </v-list-item-subtitle>
            <v-list-item-title class="text-subtitle-1">
              {{ interest.deliveryCost }}</v-list-item-title
            >
            <v-card flat class="d-flex justify-content-between pt-3">
              <p
                v-if="interest.status === 'accepted'"
                class="text-subtitle-2 green--text inline-block"
              >
                <v-icon size="25" color="green"
                  >mdi-checkbox-marked-circle-outline</v-icon
                >Request accepted. Changed your mind?
              </p>
              <v-btn
                v-else
                color="green"
                class="mr-5 text-subtitle-1 text-capitalize"
                dark
                width="40%"
                @click="acceptInterest(interest.id)"
                >Accept</v-btn
              >
              <p
                v-if="interest.status === 'declined'"
                class="text-subtitle-2 red--text inline-block"
              >
                <v-icon size="25" color="red"
                  >mdi-checkbox-marked-circle-outline</v-icon
                >Request declined. Changed your mind?
                <br />
              </p>
              <v-btn
                v-else
                color="red"
                class="text-capitalize text-subtitle-1"
                dark
                width="40%"
                @click="declineInterest(interest.id)"
                >Decline</v-btn
              >
            </v-card>
          </v-list-item-content>
        </v-list-item>
        <v-divider inset></v-divider>
      </v-list-item-group>
    </v-list>
  </Card>
</template>

<script>
import { mapActions, mapState } from "vuex";

import Card from "@/components/Card";
import NoItems from "@/components/NoItems";

export default {
  name: "InterestsDashboard",
  components: {
    Card,
    NoItems
  },
  created() {
    this.fetchSingleProduct({
      company: this.$route.query.company,
      product: this.$route.query.product
    });
  },
  computed: {
    ...mapState(["interestAndPricing"])
  },
  methods: {
    ...mapActions(["fetchSingleProduct", "acceptInterest", "declineInterest"])
  }
};
</script>
