<template>
  <Card displayControls title="Interests">
    <v-tabs class="flex-grow-0" v-model="selectedCategory" grow show-arrows>
      <v-tab v-for="category in categories" :key="category.id">
        {{ category.status }}
      </v-tab>
    </v-tabs>
    <v-list three-line nav>
      <v-list-item-group
        v-for="interest in filteredInterests"
        :key="interest.id"
      >
        <v-list-item :ripple="false" inactive>
          <v-list-item-avatar
            tile
            size="80"
            class="rounded-tr-xl grey lighten-4 elevation-1"
          >
            <v-img
              v-if="interest.product.images.length"
              :src="interest.product.images[0].url"
            ></v-img>
            <v-icon color="midnightGreen" size="60" v-else
              >account_balance</v-icon
            >
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title
              >{{ interest.product.name | capitalize }}
              <br />
              {{ interest.product_price.unit_price }} per
              {{ interest.product.unit | lowercase }}
              <br />
              {{ interest.product_price.delivery_cost }}</v-list-item-title
            >
            <v-list-item-title class="mb-1 h6 font-weight-bold">
              {{ interest.product.company.name | capitalize }}
              <br />
              {{ interest.product.company.type }}
              <br />
            </v-list-item-title>
          </v-list-item-content>
          <v-chip
            class="ma-2"
            :color="statusColor(interest)"
            label
            text-color="white"
          >
            {{ interest.status }}
          </v-chip>
        </v-list-item>
        <v-divider inset></v-divider>
      </v-list-item-group>
    </v-list>
  </Card>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import Card from "@/components/Card";

export default {
  name: "Interests",
  components: {
    Card
  },
  data() {
    return {
      selectedCategory: 0,
      categories: [
        { id: 0, status: "All" },
        { id: 1, status: "accepted" },
        { id: 2, status: "declined" },
        { id: 3, status: "notified" }
      ]
    };
  },
  created() {
    this.fetchInterests();
  },
  computed: {
    ...mapGetters(["getInterests"]),
    ...mapState(["interests"]),
    filteredInterests() {
      let status = this.categories[this.selectedCategory].status;

      if (status === "All") {
        return this.getInterests;
      } else {
        return this.getInterests.filter(item => item.status === status);
      }
    }
  },
  methods: {
    ...mapActions(["fetchInterests"]),
    statusColor({ status }) {
      switch (status) {
        case "notified":
          return "#35A19E";
        case "accepted":
          return "green";
        default:
          return "red";
      }
    }
  }
};
</script>

<style scoped>
.v-list-item__title {
  white-space: normal;
}
</style>
