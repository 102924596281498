var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Card',{attrs:{"displayControls":"","closeable":"","title":"Edit Profile"}},[_c('ValidationObserver',{ref:"obs",staticClass:"flex d-flex flex-column justify-space-between",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var changed = ref.changed;
return [_c('v-card',{staticClass:"px-7 pt-6 flex",attrs:{"flat":""}},[_c('h2',{staticClass:"midnightGreen--text"},[_vm._v(" Edit Profile ")]),_c('ImageInput'),_c('v-form',{staticClass:"px-4"},[_c('h6',{staticClass:"font-weight-bold midnightGreen--text"},[_vm._v(" Change your details ")]),_c('ValidationProvider',{attrs:{"name":"Name","mode":"eager","rules":"min:5|max:36"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input",attrs:{"type":"text","error-messages":errors,"label":"Name","placeholder":_vm.profile.name},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Email","mode":"eager","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input",attrs:{"type":"email","error-messages":errors,"label":"Email","placeholder":_vm.profile.email},model:{value:(_vm.email_change),callback:function ($$v) {_vm.email_change=$$v},expression:"email_change"}})]}}],null,true)}),_c('h6',{staticClass:"font-weight-bold midnightGreen--text"},[_vm._v(" Change your password ")]),_c('ValidationProvider',{attrs:{"name":"Old password","mode":"eager","rules":"min:5|max:36"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input",attrs:{"type":"password","error-messages":errors,"label":"Old Password"},model:{value:(_vm.old_password),callback:function ($$v) {_vm.old_password=$$v},expression:"old_password"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"New password","mode":"eager","rules":"min:5|max:36"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input",attrs:{"type":"password","label":"New Password","error-messages":errors},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"confirm","name":"Confirm password","mode":"eager","rules":"min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input",attrs:{"mode":"eager","type":"password","error-messages":errors,"label":"Confirm Password"},model:{value:(_vm.passwordConfirmed),callback:function ($$v) {_vm.passwordConfirmed=$$v},expression:"passwordConfirmed"}})]}}],null,true)})],1),_c('v-card',{staticClass:"d-flex align-items-center py-2",attrs:{"flat":""}},[_c('v-icon',{attrs:{"color":"#35A19E"}},[_vm._v("delete")]),_c('DeleteProfile',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('span',_vm._g(_vm._b({staticClass:"midnightGreen--text delete--button text-decoration-underline text-capitalize text-h6 font-weight-normal"},'span',attrs,false),on),[_vm._v("Delete Profile")])]}}],null,true)})],1)],1),_c('BaseButton',{staticClass:"d-flex align-bottom justify-self-end",attrs:{"invalid":_vm.isValidForm(invalid, changed)},nativeOn:{"click":function($event){return _vm.submitForUpdate($event)}}},[_vm._v("Done ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }