<template>
  <v-main class="white overflow-y-auto">
    <Banner>
      <MobileNavbar />
      <v-container>
        <v-img class="jukua" src="@/assets/logo beta.svg" width="17vh" />
        <v-row no-gutters :class="height">
          <v-col lg="6" cols="12">
            <h1 class="pb-3 text-h3">
              Enabling SME's to
              <strong> <br />Connect, Learn and Trade</strong>
            </h1>
            <p class="pb-3">
              Visit Jukua to get access to content which will grow your
              business.
            </p>
            <v-btn
              to="/dashboard"
              depressed
              x-large
              color="primary"
              class="text-capitalize midnightGreen--text font-weight-bold px-12"
              >Go to Jukua</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </Banner>
    <v-container class="mx-auto">
      <section>
        <v-container id="offer">
          <v-row class="pb-10">
            <v-col md="8" offset-md="2" class="text-center">
              <v-card-text class="blueGreen--text mb-2 text-h6 pa-0">
                WHAT WE DO
              </v-card-text>
              <v-card-text
                class="text-h3 font-weight-bold midnightGreen--text pa-0"
              >
                Our Offer
              </v-card-text>
              <p class="midnightGreen--text px-15 text--secondary text-center">
                We enable SME's to grow your business by expanding your
                clientelle, innovate by learning and sustain your building your
                business by constantly selling to your clientelle
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="4" md="6" cols="12">
              <v-img
                class="white--text mx-auto"
                max-width="200"
                src="@/assets/jukua-sell.png"
              ></v-img>
              <h3 class="text-center px-10">
                Connect to multiple buyers and suppliers
              </h3>
              <p class="text--secondary text-center">
                Grow your network, buy and sell products or services and trade
                with confidence
              </p>
            </v-col>
            <v-col lg="4" md="6" cols="12">
              <v-img
                class="white--text mx-auto"
                max-width="200"
                src="@/assets/Training.svg"
              >
              </v-img>
              <h3 class="text-center px-10">
                Get access to training and events
              </h3>
              <p class="text--secondary text-center">
                Grow your business through industry-specific knowledge sharing
                and training on emerging trends.
              </p>
            </v-col>
            <v-col lg="4" md="6" cols="12">
              <v-img
                class="white--text mx-auto"
                max-width="200"
                src="@/assets/Connect.svg"
              ></v-img>
              <h3 class="text-center px-10">
                Online B2B marketplace for hassle-free trading
              </h3>
              <p class="text--secondary text-center">
                Are you a buyer or a seller? Join the Jukua marketplace and get
                access to online trading with verified business partners.
              </p>
            </v-col>
          </v-row>
        </v-container>
      </section>
      <v-container id="benefits" class="py-18 mb-10">
        <v-row>
          <v-col class="col-12">
            <v-card-text class="blueGreen--text mb-2 text-h6 pa-0 pl-1">
              WHY OUR PROGRAM
            </v-card-text>
            <v-card-text
              class="text-h3 font-weight-bold midnightGreen--text pa-0"
            >
              Benefits
            </v-card-text>
            <v-card-text class="midnightGreen--text text-subtitle-1 pa-0">
              We empower SME's to expand their business via mentorship and trade
            </v-card-text>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="3" cols="12">
            <v-card minHeight="250" class="pa-4 rounded-lg">
              <v-img
                class="mb-5"
                max-width="72"
                src="@/assets/EllipseFeatures.svg"
              ></v-img>
              <p class="sub-title text-left text--secondary">
                Creation of a two-sided marketplace to extend their supplier and
                customer base
              </p>
            </v-card>
          </v-col>
          <v-col md="3" cols="12">
            <v-card minHeight="250" class="pa-4 rounded-lg">
              <v-img
                class="mb-5"
                max-width="72"
                src="@/assets/Ballot.svg"
              ></v-img>

              <p class="sub-title text-left text--secondary">
                Creation of a “business directory” for greater exposure to new
                and varied suppliers and customers.
              </p>
            </v-card>
          </v-col>
          <v-col md="3" cols="12">
            <v-card minHeight="250" class="pa-4 rounded-lg p-6">
              <v-img
                class="mb-5"
                max-width="72"
                src="@/assets/Monetary.svg"
              ></v-img>
              <p class="sub-title text-left text--secondary">
                Access to new financial services and products.
              </p>
            </v-card>
          </v-col>
          <v-col md="3" cols="12">
            <v-card minHeight="250" class="pa-4 rounded-lg">
              <v-img
                class="mb-5"
                max-width="72"
                src="@/assets/Social.svg"
              ></v-img>
              <p class="sub-title text-left text--secondary">
                Creation of mentorship channels and business-specific content
                for business owners to access.
              </p>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-container class="pt-15">
        <v-card-text class="mb-2 blueGreen--text text-h6 pa-0 pl-1"
          >LEARN MORE</v-card-text
        >
        <v-card-text class="midnightGreen--text font-weight-bold text-h3 pa-0">
          Features
        </v-card-text>
        <v-row>
          <v-col cols="12">
            <v-tabs color="blueGreen" v-model="selectedFeature" vertical grow>
              <v-tab
                v-for="feature in features"
                :key="feature.tab"
                class="font-weight-bold text-capitalize text-h6"
                v-html="feature.tab"
              >
              </v-tab>
              <v-tabs-items v-model="selectedFeature" class="pl-2">
                <v-tab-item
                  v-for="feature in features"
                  :key="feature.tab"
                  eager
                >
                  <v-row cols="12" align="center" justify="center">
                    <v-col md="6" cols="12" order="2" order-lg="1" order-xl="1">
                      <v-card-text class="px-0 text-subtitle-1">
                        {{ feature.content }}
                      </v-card-text>
                      <v-btn
                        to="/register"
                        depressed
                        x-large
                        color="primary midnightGreen--text text-capitalize font-weight-bold"
                        >Get Started</v-btn
                      >
                    </v-col>
                    <v-col md="6" cols="12" order="1" order-lg="1" order-xl="1">
                      <v-img
                        min-width="20vh"
                        max-width="25vh"
                        height="50vh"
                        :src="feature.image"
                      />
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs-items>
            </v-tabs>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <Footer />
  </v-main>
</template>

<script>
import Banner from "@/components/Banner";
import MobileNavbar from "@/components/MobileNavbar";
import Footer from "@/components/Footer";

export default {
  name: "Home",
  components: {
    Banner,
    MobileNavbar,
    Footer
  },
  data: () => ({
    selectedFeature: 0,
    features: [
      {
        tab: "Buy & Sell",
        content:
          "Grow your business through industry-specific knowledge sharing and training on emerging trends.",
        image: require("@/assets/BuySell.png")
      },
      {
        tab: "Training",
        content:
          "Get tips from experts on how to expand your business. Start your lessons now.",
        image: require("@/assets/Training.png")
      },
      {
        tab: "Mentors",
        content:
          "Benefit from advice, guidance and lessons from experts in various business industries.",
        image: require("@/assets/Mentorship.png")
      },
      {
        tab: "Price <br /> Comparison",
        content:
          "Get a variety of products with different price ranges, giving you the best opportunity to get a great deal.",
        image: require("@/assets/ComparePrices.png")
      }
    ]
  }),
  computed: {
    height() {
      switch (this.$vuetify.breakpoint.name) {
        case "sm":
        case "xs":
          return "margin-sm";
        case "md":
          return "margin-md";
        default:
          return "margin-default";
      }
    }
  }
};
</script>

<style scoped>
.feature-image {
  width: 100%;
}
</style>
