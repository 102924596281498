var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Card',{attrs:{"displayControls":"","title":"Pricing Product"}},[_c('ValidationObserver',{ref:"observer",staticClass:"d-flex flex flex-column justify-space-between",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var changed = ref.changed;
return [_c('v-card',{staticClass:"px-10 pt-7 flex",attrs:{"flat":""}},[_c('h1',{staticClass:"midnightGreen--text"},[_vm._v(" Update Product "),_c('br'),_vm._v("Pricing ")]),(_vm.pricing)?_c('v-form',[_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('ValidationProvider',{attrs:{"name":"Price","rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input",attrs:{"type":"text","label":"From price in Kshs","error-messages":errors,"placeholder":_vm.priceFrom},model:{value:(_vm.from_price),callback:function ($$v) {_vm.from_price=_vm._n($$v)},expression:"from_price"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('p',{staticClass:"mt-4 font-weight-medium text-center to"},[_vm._v("to")])]),_c('v-col',{attrs:{"cols":"5"}},[_c('ValidationProvider',{attrs:{"name":"Price","rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input",attrs:{"type":"text","label":"Price in Kshs","error-messages":errors,"placeholder":_vm.priceTo},model:{value:(_vm.to_price),callback:function ($$v) {_vm.to_price=_vm._n($$v)},expression:"to_price"}})]}}],null,true)})],1)],1),_c('ValidationProvider',{attrs:{"name":"Quantity","rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Minimum Order Quantity","error-messages":errors,"placeholder":_vm.pricing.min_units},model:{value:(_vm.min_units),callback:function ($$v) {_vm.min_units=_vm._n($$v)},expression:"min_units"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Cost of Delivery","rules":"min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"input",attrs:{"label":"Cost of Delivery","items":_vm.deliveryCosts,"placeholder":_vm.pricing.delivery_cost,"error-messages":errors},model:{value:(_vm.delivery_cost),callback:function ($$v) {_vm.delivery_cost=$$v},expression:"delivery_cost"}})]}}],null,true)})],1):_vm._e()],1),_c('v-card',{staticClass:"d-flex align-items-center px-10 pb-5",attrs:{"flat":""}},[_c('v-icon',{attrs:{"color":"#35A19E"}},[_vm._v("delete")]),_c('span',{staticClass:"midnightGreen--text delete--button text-decoration-underline text-capitalize text-h6 font-weight-normal",on:{"click":function($event){return _vm.confirmationAlert({
            product: _vm.$route.params.productId,
            price_point_id: _vm.pricing.id,
            propertyToDelete: 'pricing'
          })}}},[_vm._v("Delete Pricing")])],1),_c('BaseButton',{attrs:{"buttonColor":"blueGreen","textColor":"white","invalid":_vm.isUpdated(invalid, changed)},nativeOn:{"click":function($event){return _vm.updatePricing({
          id: _vm.$route.params.productId,
          companyId: _vm.$route.query.company,
          data: {
            unit_price: ((_vm.from_price ? _vm.from_price : _vm.priceFrom) + " KES to " + (_vm.to_price ? _vm.to_price : _vm.priceTo) + " KES"),
            min_units: _vm.min_units ? _vm.min_units : _vm.pricing.min_units,
            delivery_cost: _vm.delivery_cost
              ? _vm.delivery_cost
              : _vm.pricing.delivery_cost,
            price_point_id: _vm.$route.params.pricingId
          }
        })}}},[_vm._v("Save")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }