<template>
  <Card displayControls closeable title="Edit Profile">
    <ValidationObserver
      class="flex d-flex flex-column justify-space-between"
      ref="obs"
      v-slot="{ invalid, changed }"
    >
      <v-card flat class="px-7 pt-6 flex">
        <h2 class="midnightGreen--text">
          Edit Profile
        </h2>
        <ImageInput />
        <v-form class="px-4">
          <h6 class="font-weight-bold midnightGreen--text">
            Change your details
          </h6>
          <ValidationProvider
            name="Name"
            mode="eager"
            rules="min:5|max:36"
            v-slot="{ errors }"
          >
            <v-text-field
              class="input"
              type="text"
              v-model="name"
              :error-messages="errors"
              label="Name"
              :placeholder="profile.name"
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider
            name="Email"
            mode="eager"
            rules="email"
            v-slot="{ errors }"
          >
            <v-text-field
              class="input"
              type="email"
              v-model="email_change"
              :error-messages="errors"
              label="Email"
              :placeholder="profile.email"
            ></v-text-field>
          </ValidationProvider>
          <h6 class="font-weight-bold midnightGreen--text">
            Change your password
          </h6>
          <ValidationProvider
            name="Old password"
            mode="eager"
            rules="min:5|max:36"
            v-slot="{ errors }"
          >
            <v-text-field
              class="input"
              type="password"
              v-model="old_password"
              :error-messages="errors"
              label="Old Password"
            ></v-text-field>
          </ValidationProvider>

          <ValidationProvider
            name="New password"
            mode="eager"
            rules="min:5|max:36"
            v-slot="{ errors }"
          >
            <v-text-field
              class="input"
              type="password"
              v-model="password"
              label="New Password"
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider
            vid="confirm"
            name="Confirm password"
            mode="eager"
            rules="min:5"
            v-slot="{ errors }"
          >
            <v-text-field
              class="input"
              mode="eager"
              type="password"
              v-model="passwordConfirmed"
              :error-messages="errors"
              label="Confirm Password"
            ></v-text-field>
          </ValidationProvider>
        </v-form>
        <v-card flat class="d-flex align-items-center py-2">
          <v-icon color="#35A19E">delete</v-icon>
          <DeleteProfile v-slot="{ attrs, on }">
            <span
              v-bind="attrs"
              v-on="on"
              class="midnightGreen--text delete--button text-decoration-underline text-capitalize text-h6 font-weight-normal"
              >Delete Profile</span
            >
          </DeleteProfile>
        </v-card>
      </v-card>
      <BaseButton
        class="d-flex align-bottom justify-self-end"
        @click.native="submitForUpdate"
        :invalid="isValidForm(invalid, changed)"
        >Done
      </BaseButton>
    </ValidationObserver>
  </Card>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

import Card from "@/components/Card";
import BaseButton from "@/components/BaseButton";
import DeleteProfile from "@/components/DeleteProfile";
import ImageInput from "@/components/ImageInput";

export default {
  name: "EditProfile",
  components: {
    Card,
    BaseButton,
    DeleteProfile,
    ImageInput
  },
  data() {
    return {
      name: "",
      email_change: "",
      old_password: "",
      password: "",
      passwordConfirmed: "",
      show: false
    };
  },
  created() {
    this.fetchProfile();
    this.fetchProfileImageUuid();
  },
  beforeDestroy() {
    this.setPreviewImage(null);
  },
  computed: {
    ...mapState(["profile", "previewImage", "isLoading"])
  },
  methods: {
    ...mapActions([
      "changePassword",
      "updateProfile",
      "deleteProfile",
      "uploadFiles",
      "fetchProfileImageUuid",
      "fetchProfile"
    ]),
    ...mapMutations(["setPreviewImage"]),
    isValidForm(invalid, changed) {
      if (!invalid && changed) {
        return false;
      }

      if (this.previewImage) {
        return false;
      }

      return true;
    },
    submitForUpdate() {
      if (this.old_password && this.password) {
        this.changePassword({
          old_password: this.old_password,
          password: this.password
        });
      }

      if (this.name && this.email_change) {
        this.updateProfile({
          name: this.name,
          email_change: this.email_change
        });
      }

      if (this.name) {
        this.updateProfile({
          name: this.name,
          message: "Successfully updated!"
        });
      }

      if (this.email_change) {
        this.updateProfile({
          email_change: this.email_change
        });
      }

      if (this.previewImage) {
        this.uploadFiles({ id: this.profile.id, category: "profile" });
      }
    }
  }
};
</script>

<style scoped>
.delete--button:hover {
  cursor: pointer;
}
</style>
