<template>
  <Card
    displayControls
    :title="companyDetails.companyName"
    :editable="mine ? (editable ? false : true) : false"
    editMessage="Edit product"
    :to="
      `/product/${$route.params.id}/delete?company=${this.$route.query.company}`
    "
  >
    <ProductImage :companyProducts="companyProducts" />
    <v-list class="px-6 flex" three-line nav>
      <v-radio-group v-model="prices">
        <v-list-item-group v-for="price in getPricing" :key="price.id">
          <v-list-item :ripple="false" inactive>
            <v-radio v-if="deletable" color="blueGreen" :value="price.id" />
            <v-list-item-content>
              <v-list-item-title
                class="text-h6 font-weight-normal blueGreen--text"
                >{{ price.unit_price }}
              </v-list-item-title>
              <v-list-item-subtitle>
                per {{ companyDetails.unit | lowercase }} &#9679; Min. Order
                {{ price.min_units }}
              </v-list-item-subtitle>
              <v-list-item-title class="text-subtitle-1">
                {{ price.delivery_cost }}</v-list-item-title
              >
            </v-list-item-content>
            <v-icon
              v-if="deletable"
              @click="
                confirmationAlert({
                  product: $route.params.id,
                  price_point_id: price.id,
                  sameRoute: true,
                  propertyToDelete: 'pricing'
                })
              "
              :disabled="prices !== price.id"
              size="30"
              color="blueGreen"
              >mdi-delete-forever</v-icon
            >
          </v-list-item>
          <v-divider inset></v-divider>
        </v-list-item-group>
      </v-radio-group>
    </v-list>
    <v-list v-if="!hideImages && productImages.length > 1" class="px-6">
      <v-list-item-title class="text-h6 midnight--green pb-3"
        >Product Images</v-list-item-title
      >
      <v-list-item class="d-flex overflow-x-auto">
        <v-list-item-group v-for="image in productImages" :key="image.id">
          <v-list-item-avatar size="100">
            <v-img :src="image.url"></v-img>
          </v-list-item-avatar>
        </v-list-item-group>
      </v-list-item>
      <v-divider inset></v-divider>
    </v-list>
    <BaseButton
      v-if="
        !disableInterestButton &&
          companyProducts.interests &&
          companyProducts.interests.length
      "
      buttonColor="blueGreen"
      textColor="white"
      :to="{
        path: '/interests-dashboard',
        query: {
          product: $route.params.id,
          company: $route.query.company
        }
      }"
      >Interests</BaseButton
    >
    <slot />
  </Card>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

import Card from "@/components/Card";
import ProductImage from "@/components/ProductImage";
import BaseButton from "@/components/BaseButton";

export default {
  name: "Product",
  components: {
    Card,
    BaseButton,
    ProductImage
  },
  props: {
    editable: Boolean,
    disableInterestButton: Boolean,
    deletable: Boolean,
    hideImages: Boolean
  },
  data() {
    return {
      mine: false,
      displayInterest: true,
      prices: null
    };
  },
  created() {
    this.fetchSingleProduct({
      company: this.$route.query.company,
      product: this.$route.params.id
    });
    this.fetchCompanies();
    this.matchRoute();
  },
  computed: {
    ...mapGetters(["getPricing"]),
    ...mapState([
      "companyProducts",
      "companyDetails",
      "myCompanies",
      "interests",
      "productImages"
    ])
  },
  methods: {
    ...mapActions([
      "fetchCompanies",
      "fetchSingleProduct",
      "confirmationAlert"
    ]),
    matchRoute() {
      const company = this.myCompanies.find(
        company => parseInt(this.$route.query.company) === company.id
      );

      if (company) {
        this.mine = true;
      }
    }
  }
};
</script>
