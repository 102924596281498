var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Card',{attrs:{"displayControls":"","notifications":""}},[_c('ValidationObserver',{ref:"observer",staticClass:"d-flex flex flex-column justify-space-between",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',{staticClass:"px-10 pt-7 flex",attrs:{"flat":""}},[_c('h1',{staticClass:"midnightGreen--text"},[_vm._v(" Pricing "),_c('br'),_vm._v("Product ")]),_c('v-form',[_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('ValidationProvider',{attrs:{"name":"Price","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input",attrs:{"type":"text","label":"From price in Kshs","error-messages":errors},model:{value:(_vm.from_price),callback:function ($$v) {_vm.from_price=_vm._n($$v)},expression:"from_price"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('p',{staticClass:"mt-4 font-weight-medium text-center to"},[_vm._v(" to ")])]),_c('v-col',{attrs:{"cols":"5"}},[_c('ValidationProvider',{attrs:{"name":"Price","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input",attrs:{"type":"text","label":"Price in Kshs","error-messages":errors},model:{value:(_vm.to_price),callback:function ($$v) {_vm.to_price=_vm._n($$v)},expression:"to_price"}})]}}],null,true)})],1)],1),_c('ValidationProvider',{attrs:{"name":"Quantity","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Minimum Order Quantity","error-messages":errors},model:{value:(_vm.min_units),callback:function ($$v) {_vm.min_units=_vm._n($$v)},expression:"min_units"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Cost of Delivery","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"input",attrs:{"label":"Cost of Delivery","items":_vm.deliveryCosts,"error-messages":errors},model:{value:(_vm.delivery_cost),callback:function ($$v) {_vm.delivery_cost=$$v},expression:"delivery_cost"}})]}}],null,true)}),_c('h3',{staticClass:"midnightGreen--text"},[_vm._v("Pricing Flexibility")]),_c('v-row',[_c('v-col',[_c('p',[_vm._v("Enable discounting")])]),_c('v-col',{staticClass:"d-flex align-items-end"},[_c('v-switch',{model:{value:(_vm.enable_discounting),callback:function ($$v) {_vm.enable_discounting=$$v},expression:"enable_discounting"}})],1)],1),_c('ValidationProvider',{attrs:{"name":"Discount Threshhold","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Minimum Quantity for discount","error-messages":errors},model:{value:(_vm.discount_threshhold),callback:function ($$v) {_vm.discount_threshhold=_vm._n($$v)},expression:"discount_threshhold"}})]}}],null,true)})],1)],1),_c('BaseButton',{attrs:{"buttonColor":"blueGreen","textColor":"white","invalid":invalid},nativeOn:{"click":function($event){return _vm.addPricing({
          id: _vm.$route.params.productId,
          company: _vm.$route.params.companyId,
          route: _vm.$route.query.route,
          data: { from_price: _vm.from_price, to_price: _vm.to_price, min_units: _vm.min_units, delivery_cost: _vm.delivery_cost }
        })}}},[_vm._v("Next")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }