<template>
  <v-overlay :z-index="20" opacity="1" fluid color="white" v-if="isLoading">
    <v-progress-circular
      :size="70"
      :width="7"
      indeterminate
      color="#ff5666"
      class="m-auto"
    ></v-progress-circular>
  </v-overlay>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Loading",
  computed: {
    ...mapGetters(["isLoading"])
  }
};
</script>
