<template>
  <Card displayControls>
    <ValidationObserver
      class="d-flex flex flex-column justify-space-between"
      ref="observer"
      v-slot="{ changed }"
    >
      <v-card flat class="px-7 pt-8">
        <h1 class="midnightGreen--text">
          Company
          <br />Logo
        </h1>
        <div class="pt-16 d-flex justify-center imageContainer">
          <ImageInput
            image
            imageSize="220"
            iconSize="150"
            iconPosition="top: 9rem; left: 12rem;"
            edgeIconSize="100"
          />
        </div>
      </v-card>
      <div>
        <div
          class="midnightGreen--text text-body-1 px-3 pb-5 body-1 text-decoration-underline"
        >
          <router-link
            class="router-link"
            :to="{ path: '/company/' + $route.query.company }"
            >Skip this process and upload later</router-link
          >
        </div>
        <BaseButton
          @click.native="submitLogo"
          :invalid="isImageSelected(changed)"
          >Submit</BaseButton
        >
      </div>
    </ValidationObserver>
  </Card>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";

import Card from "@/components/Card";
import BaseButton from "@/components/BaseButton";
import ImageInput from "@/components/ImageInput";

export default {
  name: "CompanyLogo",
  components: {
    Card,
    BaseButton,
    ImageInput
  },
  data() {
    return {};
  },
  created() {
    this.fetchCompanyDocumentUuids();
  },
  computed: {
    ...mapState(["previewImage", "documents", "isLoading", "myCompanies"]),
    ...mapGetters(["isNext"])
  },
  methods: {
    ...mapMutations(["setUploadUuid", "setNextToFalse"]),
    ...mapActions([
      "onFileSelected",
      "fetchCompanyDocumentUuids",
      "uploadFiles"
    ]),
    selectImage() {
      this.$refs.logo.click();
    },
    isImageSelected(changed) {
      if (changed) {
        return false;
      }

      if (this.previewImage) {
        return false;
      }

      return true;
    },
    async submitLogo() {
      if (this.documents.logo_uuid) {
        await this.setUploadUuid(this.documents.logo_uuid);

        await this.uploadFiles({
          id: this.$route.query.company,
          category: "company",
          next: true
        });

        if (this.isNext) {
          this.$router.push(`/company/${this.$route.query.company}`);
          this.setNextToFalse();
        }
      }
    }
  }
};
</script>
