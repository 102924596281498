<template>
  <nav>
    <v-navigation-drawer
      v-if="$vuetify.breakpoint.mobile"
      v-model="drawer"
      width="350"
      hide-overlay
      app
    >
      <v-list>
        <v-row no-gutters class="mx-4 my-3 mb-10">
          <v-col align="left">
            <v-icon color="midnightGreen" @click="drawer = !drawer"
              >mdi-close</v-icon
            >
          </v-col>
        </v-row>
        <v-list-item
          v-for="item in linksToDisplay"
          :key="item.title"
          :to="item.path"
          class="mt-5"
        >
          <v-list-item-content
            class="midnightGreen--text text-h6 font-weight-bold"
            >{{ item.title }}</v-list-item-content
          >
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-toolbar flat color="transparent" class="d-flex justify-end">
      <span class="header--nav" v-if="$vuetify.breakpoint.mobile">
        <v-app-bar-nav-icon @click="drawer = !drawer"> </v-app-bar-nav-icon>
      </span>
      <v-toolbar-items class="mt-5" v-if="!$vuetify.breakpoint.mobile">
        <v-btn
          text
          to="/"
          class="midnightGreen--text mx-1 text-capitalize font-weight-bold text-h6"
        >
          Home
        </v-btn>
        <v-btn
          text
          to="/dashboard"
          class="midnightGreen--text mx-1 text-capitalize font-weight-bold text-h6"
        >
          Dashboard
        </v-btn>
        <v-btn
          text
          to="/faqs"
          class="midnightGreen--text mx-1 text-capitalize font-weight-bold text-h6"
        >
          FAQs
        </v-btn>
        <v-btn
          v-if="!isLoggedIn"
          color="yellow darken-3"
          to="/register"
          class="midnightGreen--text mx-1 rounded-lg px-8 text-capitalize font-weight-bold text-h6"
        >
          Sign Up
        </v-btn>
        <v-btn
          v-if="!isLoggedIn"
          text
          to="/login"
          class="midnightGreen--text mx-1 text-capitalize font-weight-bold text-h6"
        >
          Sign In
        </v-btn>
        <v-btn
          text
          to="/training"
          class="midnightGreen--text mx-1 text-capitalize font-weight-bold text-h6"
        >
          Training
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MobileNavbar",
  data: () => ({
    drawer: false,
    loggedOutLinks: [
      { title: "Home", path: "/" },
      { title: "Dashboard", path: "/dashboard" },
      { title: "FAQs", path: "/faqs" },
      { title: "Sign Up", path: "/register" },
      { title: "Sign In", path: "/login" },
      { title: "Training", path: "/training" }
    ],
    loggedInLinks: [
      { title: "Home", path: "/" },
      { title: "Dashboard", path: "/dashboard" },
      { title: "FAQs", path: "/faqs" },
      { title: "Profile", path: "/profile" },
      { title: "Notifications", path: "/notifications" },
      { title: "Interests", path: "/interests" },
      { title: "Training", path: "/training" },
      { title: "Logout", path: "/logout" }
    ]
  }),
  computed: {
    ...mapGetters(["isLoggedIn"]),
    linksToDisplay() {
      return this.isLoggedIn ? this.loggedInLinks : this.loggedOutLinks;
    }
  }
};
</script>

<style scoped>
.header--nav {
  position: absolute;
  right: 2%;
}

.v-application .v-list-item--active {
  text-decoration: underline;
  text-decoration-color: #ff5666;
  text-decoration-thickness: 4px !important;
  background: white !important;
}
</style>
