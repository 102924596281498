<template>
  <Card
    classes="px-5 pt-5"
    displayControls
    title="Dashboard"
    notifications
    to="/notifications"
  >
    <v-alert dense border="left" type="warning" v-if="!isLoggedIn">
      <router-link to="/login">
        You are viewing Jukua as a guest.
        <strong>Sign in/Sign up</strong>
      </router-link>
    </v-alert>
    <v-alert
      dense
      border="left"
      type="warning"
      v-if="isLoggedIn && !myCompanies.length"
    >
      You do not have any companies registered yet.
      <router-link to="/register-onboarding">
        <strong>Register Now!</strong>
      </router-link>
    </v-alert>

    <v-card flat v-if="companies.length">
      <v-card flat>
        <v-card-title class="text-h5 midnightGreen--text font-weight-bold"
          >Featured Products</v-card-title
        >
        <v-card-text>
          <v-text-field
            v-model="searchProductQuery"
            @input="isTyping = true"
            label="What do you want to buy?"
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-card-text>
        <v-tabs
          v-if="filteredProducts.length"
          color="#FF5666"
          v-model="selectedCategory"
          grow
          show-arrows
        >
          <v-tab
            class="text-capitalize text-h6 midnightGreen--text"
            v-for="category in preferredCategory"
            :key="category.id"
            >{{ category.name }}</v-tab
          >
        </v-tabs>
        <v-row v-if="filteredProducts.length">
          <v-col
            lg="4"
            md="4"
            sm="12"
            cols="12"
            class="px-2"
            v-for="(product, index) in filteredProducts"
            :key="index"
          >
            <v-card flat>
              <router-link
                :to="toRoute(product.id, product.company_id, filteredProducts)"
              >
                <v-img
                  v-if="product.images"
                  :src="product.images[product.images.length - 1].url"
                  height="15vh"
                  class="rounded-tr-xl elevation-3"
                ></v-img>
                <v-img
                  v-else
                  src="@/assets/company-placeholder.svg"
                  height="15vh"
                  class="rounded-tr-xl elevation-3"
                ></v-img>
                <v-card tile flat>
                  <v-card-title class="pa-1 dark--text text-capitalize">{{
                    product.name
                  }}</v-card-title>
                  <v-card-text class="pa-1">
                    {{ product.pricing[0].unit_price }} per
                    {{ product.unit | lowercase }}
                  </v-card-text>
                </v-card>
                <v-card tile flat class="px-2 py-1 d-flex justify-end">
                  <v-icon
                    class="d-flex flex justify-start"
                    v-if="
                      user_uuid ? product.company.user_uuid === user_uuid : null
                    "
                    fab
                    color="#F8B234"
                    >mdi-account-star</v-icon
                  >
                  <v-btn
                    class="flex-end"
                    small
                    outlined
                    color="primary"
                    fab
                    dark
                  >
                    <v-icon size="30">mdi-chevron-right</v-icon>
                  </v-btn>
                </v-card>
              </router-link>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="!filteredProducts.length">
          <v-col>
            <v-card-text class="text-center text-h6"
              >No products matching search</v-card-text
            >
          </v-col>
        </v-row>
        <Pagination
          v-if="pages.products && !searchProductQuery"
          :currentPage="pages.products.currentPage"
          :lastPage="pages.products.lastPage"
          :fetchNextFunction="fetchProducts"
        />
        <Pagination
          v-if="pages.searchedProducts && searchProductQuery"
          :currentPage="pages.searchedProducts.currentPage"
          :lastPage="pages.searchedProducts.lastPage"
          :fetchNextFunction="searchProducts"
          :searchTerm="searchProductQuery"
        />
      </v-card>

      <v-card flat class="py-12">
        <v-card-title class="text-h5 midnightGreen--text font-weight-bold"
          >Featured Companies</v-card-title
        >
        <v-card-text>
          <v-text-field
            v-model="searchCompanyQuery"
            @input="isTyping = true"
            label="Search companies"
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-card-text>
        <v-row v-if="queryCompanies.length">
          <v-col
            lg="4"
            md="6"
            sm="12"
            cols="12"
            class="px-2"
            v-for="company in queryCompanies"
            :key="company.id"
          >
            <v-card flat>
              <router-link
                :to="{
                  path: `/company/${company.id}`,
                  query: {
                    from: '/dashboard'
                  }
                }"
              >
                <v-img
                  v-if="company.logo"
                  :src="company.logo.url"
                  height="15vh"
                  class="rounded-tr-xl elevation-3"
                ></v-img>
                <v-img
                  v-else
                  src="@/assets/company-placeholder.svg"
                  height="15vh"
                  class="rounded-tr-xl elevation-3"
                ></v-img>
                <v-card tile flat>
                  <v-card-title class="pa-1 dark--text text-capitalize">{{
                    company.name | capitalize
                  }}</v-card-title>
                  <v-card-text class="pa-1">{{ company.type }}</v-card-text>
                </v-card>
                <v-card
                  tile
                  flat
                  class="px-2 py-1 d-flex align-center justify-space-between"
                >
                  <v-chip
                    small
                    color="secondaryNeutral"
                    text-color="midnightGreen"
                    label
                    class="text-uppercase"
                    >{{ company.physical_location }}</v-chip
                  >
                  <v-icon v-if="company.mine === 'Y'" fab color="#F8B234"
                    >mdi-account-star</v-icon
                  >
                  <v-btn
                    class="flex-end"
                    small
                    outlined
                    color="primary"
                    fab
                    dark
                  >
                    <v-icon size="30">mdi-chevron-right</v-icon>
                  </v-btn>
                </v-card>
              </router-link>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="!queryCompanies.length">
          <v-col>
            <v-card-text class="text-center text-h6"
              >No companies matching search</v-card-text
            >
          </v-col>
        </v-row>
        <Pagination
          v-if="pages.companies && !searchCompanyQuery"
          :currentPage="pages.companies.currentPage"
          :lastPage="pages.companies.lastPage"
          :fetchNextFunction="fetchCompanies"
        />
        <Pagination
          v-if="pages.searchedCompanies && searchCompanyQuery"
          :currentPage="pages.searchedCompanies.currentPage"
          :lastPage="pages.searchedCompanies.lastPage"
          :fetchNextFunction="searchCompanies"
          :searchTerm="searchCompanyQuery"
        />
      </v-card>
    </v-card>
    <v-card flat v-else>
      <v-card flat align="center">
        <v-card-title class="text-h5 midnightGreen--text font-weight-bold"
          >Featured Products</v-card-title
        >
        <hr />
        <v-card flat class="px-10">
          <v-img width="50%" src="@/assets/noProducts.png" />
        </v-card>
        <v-card-text class="text-h6 font-weight-bold midnightGreen--text pt-5"
          >No products yet...</v-card-text
        >
      </v-card>
      <v-card flat align="center" class="pt-5">
        <v-card-title class="text-h5 midnightGreen--text font-weight-bold"
          >Featured Companies</v-card-title
        >
        <hr />
        <NoItems :isPopulated="!companies.length" items
          >No companies yet...</NoItems
        >
      </v-card>
    </v-card>
  </Card>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

import Card from "@/components/Card";
import NoItems from "@/components/NoItems";
import Pagination from "@/components/Pagination";

export default {
  name: "Dashboard",
  components: {
    Card,
    NoItems,
    Pagination
  },
  data() {
    return {
      searchProductQuery: "",
      searchCompanyQuery: "",
      selectedCategory: 0,
      isTyping: false
    };
  },
  created() {
    this.fetchProducts();
    if (this.$store.state.token) {
      this.fetchMyCompanies();
    }
    this.setCurrentUserUuid();
  },
  watch: {
    isTyping(typing) {
      if (typing) {
        setTimeout(() => {
          this.isTyping = false;

          if (this.searchProductQuery) {
            this.initiateSearchProducts();
          }

          if (this.searchCompanyQuery) {
            this.initiateSearchCompanies();
          }
        }, 500);
      }
    }
  },
  computed: {
    ...mapState([
      "companies",
      "myCompanies",
      "products",
      "user_uuid",
      "categories",
      "pages",
      "searchedProducts",
      "searchedCompanies",
      "searchedCategories"
    ]),
    ...mapGetters(["isLoggedIn"]),
    preferredCategory() {
      if (this.searchProductQuery && this.searchedCategories.length) {
        return this.searchedCategories;
      }

      return this.categories;
    },
    filteredProducts() {
      let category;

      if (this.categories[this.selectedCategory]) {
        category = this.categories[this.selectedCategory].name;
      }

      if (this.searchedCategories.length) {
        category = this.searchedCategories[this.selectedCategory].name;
      }

      if (category && category === "All") {
        return this.queryProducts;
      } else {
        const products = this.queryProducts;
        return products.filter(product => product.category === category);
      }
    },
    queryProducts() {
      if (this.searchProductQuery && this.searchedProducts.length) {
        return this.searchedProducts;
      } else if (this.searchProductQuery && !this.searchedProducts.length) {
        return [];
      } else {
        return this.products;
      }
    },
    queryCompanies() {
      if (this.searchCompanyQuery && this.searchedCompanies.length) {
        return this.searchedCompanies;
      } else if (this.searchCompanyQuery && !this.searchedCompanies.length) {
        return [];
      } else {
        return this.companies;
      }
    }
  },
  methods: {
    ...mapActions([
      "fetchProducts",
      "setCurrentUserUuid",
      "fetchCompanies",
      "fetchMyCompanies",
      "searchProducts",
      "searchCompanies"
    ]),
    toRoute(productId, companyId, filteredProducts) {
      const [product] = filteredProducts.filter(
        product => product.id === productId
      );

      const [company] = this.myCompanies.filter(
        company => company.id === product.company_id
      );

      const routeMyCompany = {
        path: "/product/" + productId,
        query: {
          company: companyId
        }
      };

      const expressRoute = {
        path: "/interest",
        query: {
          company: companyId,
          product: productId
        }
      };

      if (company) {
        return routeMyCompany;
      } else {
        return expressRoute;
      }
    },
    initiateSearchProducts() {
      if (!this.isTyping) {
        setTimeout(() => {
          this.searchProducts({ query: this.searchProductQuery });
        }, 800);
      }
    },
    initiateSearchCompanies() {
      if (!this.isTyping) {
        setTimeout(() => {
          this.searchCompanies({ query: this.searchCompanyQuery });
        }, 800);
      }
    }
  }
};
</script>
