<template>
  <v-card flat>
    <v-card flat class="px-6 pt-6">
      <v-card tile flat class="rounded-tr-xl container--relative elevation-3">
        <v-img
          v-if="companyProducts.images"
          height="30vh"
          :src="companyProducts.images[companyProducts.images.length - 1].url"
        ></v-img>
        <v-img v-else height="30vh" src="../assets/no-company-logo.jpg">
        </v-img>
      </v-card>
      <v-card tile flat class="d-flex align-center justify-space-between">
        <v-card-title class="midnightGreen--text px-0 text-h6">{{
          companyProducts.name
        }}</v-card-title>
        <v-chip
          small
          class="text-uppercase"
          color="secondaryNeutral"
          text-color="midnightGreen"
          label
          >{{ companyProducts.location }}</v-chip
        >
      </v-card>
    </v-card>
  </v-card>
</template>

<script>
export default {
  name: "ProductImage",
  props: {
    companyProducts: [Object, Array],
    editable: Boolean
  }
};
</script>
