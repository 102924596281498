<template>
  <Card displayControls title="Pricing Product">
    <ValidationObserver
      class="d-flex flex flex-column justify-space-between"
      ref="observer"
      v-slot="{ invalid, changed }"
    >
      <v-card class="px-10 pt-7 flex" flat>
        <h1 class="midnightGreen--text">
          Update Product
          <br />Pricing
        </h1>
        <v-form v-if="pricing">
          <v-row>
            <v-col cols="5">
              <ValidationProvider
                name="Price"
                rules="numeric"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model.number="from_price"
                  class="input"
                  type="text"
                  label="From price in Kshs"
                  :error-messages="errors"
                  :placeholder="priceFrom"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="2">
              <p class="mt-4 font-weight-medium text-center to">to</p>
            </v-col>
            <v-col cols="5">
              <ValidationProvider
                name="Price"
                rules="numeric"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model.number="to_price"
                  class="input"
                  type="text"
                  label="Price in Kshs"
                  :error-messages="errors"
                  :placeholder="priceTo"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>
          <ValidationProvider
            name="Quantity"
            rules="numeric"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model.number="min_units"
              label="Minimum Order Quantity"
              :error-messages="errors"
              :placeholder="pricing.min_units"
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider
            name="Cost of Delivery"
            rules="min:3"
            v-slot="{ errors }"
          >
            <v-select
              v-model="delivery_cost"
              label="Cost of Delivery"
              :items="deliveryCosts"
              :placeholder="pricing.delivery_cost"
              :error-messages="errors"
              class="input"
            ></v-select>
          </ValidationProvider>
        </v-form>
      </v-card>
      <v-card flat class="d-flex align-items-center px-10 pb-5">
        <v-icon color="#35A19E">delete</v-icon>
        <span
          @click="
            confirmationAlert({
              product: $route.params.productId,
              price_point_id: pricing.id,
              propertyToDelete: 'pricing'
            })
          "
          class="midnightGreen--text delete--button text-decoration-underline text-capitalize text-h6 font-weight-normal"
          >Delete Pricing</span
        >
      </v-card>
      <BaseButton
        buttonColor="blueGreen"
        textColor="white"
        :invalid="isUpdated(invalid, changed)"
        @click.native="
          updatePricing({
            id: $route.params.productId,
            companyId: $route.query.company,
            data: {
              unit_price: `${from_price ? from_price : priceFrom} KES to ${
                to_price ? to_price : priceTo
              } KES`,
              min_units: min_units ? min_units : pricing.min_units,
              delivery_cost: delivery_cost
                ? delivery_cost
                : pricing.delivery_cost,
              price_point_id: $route.params.pricingId
            }
          })
        "
        >Save</BaseButton
      >
    </ValidationObserver>
  </Card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import Card from "@/components/Card";
import BaseButton from "@/components/BaseButton";

export default {
  name: "ProductPricingUpdate",
  components: {
    Card,
    BaseButton
  },
  data() {
    return {
      from_price: "",
      to_price: "",
      min_units: "",
      delivery_cost: "",
      categories: ["Agriculture", "Electronics", "Fashion", "Other"],
      deliveryCosts: [
        "Free in all areas",
        "Free in some areas",
        "User pay for delivery"
      ],
      priceFrom: "",
      priceTo: "",
      pricing: null
    };
  },
  created() {
    this.fetchSingleProduct({
      company: this.$route.query.company,
      product: this.$route.params.productId
    });
    this.pricingData(this.getPricing);
    this.priceRanges(this.pricing);
  },
  computed: {
    ...mapGetters(["getPricing"])
  },
  methods: {
    ...mapActions(["fetchSingleProduct", "updatePricing", "confirmationAlert"]),
    priceRanges({ unit_price }) {
      const [from_price, to_price] = unit_price.split(" to ");
      this.priceFrom = from_price;
      this.priceTo = to_price;
    },
    pricingData(pricing) {
      const [price] = pricing.filter(price => {
        return price.id === parseInt(this.$route.params.pricingId);
      });
      this.pricing = price;
    },
    isUpdated(invalid, changed) {
      if (!invalid && changed) {
        return false;
      }

      return true;
    }
  }
};
</script>
