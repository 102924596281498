<template>
  <Product deletable hideImages disableInterestButton editable :to="toRoute">
    <v-card flat class="d-flex align-center pb-2 px-6">
      <v-icon color="#35A19E">delete</v-icon>
      <span
        @click="
          confirmationAlert({
            company: $route.query.company,
            product: $route.params.id,
            propertyToDelete: 'product'
          })
        "
        class="midnightGreen--text delete--button text-decoration-underline text-capitalize text-h6 font-weight-normal"
        >Delete Product</span
      >
    </v-card>
    <BaseButton buttonColor="blueGreen" textColor="white" :to="toRoute"
      >Edit Product</BaseButton
    >
  </Product>
</template>

<script>
import { mapActions } from "vuex";

import Product from "@/views/Product";
import BaseButton from "@/components/BaseButton";

export default {
  name: "EditProduct",
  components: {
    BaseButton,
    Product
  },
  computed: {
    toRoute() {
      return `/product/${this.$route.params.id}/edit?company=${this.$route.query.company}`;
    }
  },
  methods: mapActions(["confirmationAlert"])
};
</script>
