<template>
  <Card displayControls :title="companyDetails.companyName | capitalize">
    <v-card
      flat
      class="d-flex flex flex-column justify-space-between"
      v-if="isInterested"
    >
      <div class="pa-16">
        <SuccessIcon width="100">
          A notification of your interest has been sent to
          {{ companyDetails.companyName | capitalize }}
        </SuccessIcon>
      </div>
      <BaseButton buttonColor="blueGreen" textColor="white" to="/dashboard"
        >Continue Shopping</BaseButton
      >
    </v-card>
    <v-card class="pa-0" flat v-if="!mine && !isInterested">
      <v-card flat class="pa-0">
        <v-card tile flat class="rounded-tr-xl pa-5">
          <v-img :src="companyDetails.logo" class="elevation-1" height="20vh" />
        </v-card>
        <v-row class="mx-1">
          <v-col cols="12"> </v-col>
          <v-col cols="8">
            <v-card-title class="darkBlue--text px-0 text-h5 text-capitalize">{{
              companyProducts.name
            }}</v-card-title>
            <v-chip
              small
              color="blueGreen"
              text-color="white"
              class="text-subtitle-1"
              label
              >{{ companyProducts.location }}</v-chip
            >
          </v-col>
        </v-row>
      </v-card>
      <v-divider />
      <v-card v-if="pricing.length" flat class="mx-5">
        <v-card-title class="text-h6 darkBlue--text font-weight-bold"
          >Select Price(s)</v-card-title
        >
        <v-list three-line nav>
          <v-radio-group v-model="radios">
            <v-list-item-group v-for="price in pricing" :key="price.id">
              <v-list-item :ripple="false" inactive>
                <v-radio :value="price.id" class="pr-3" />
                <v-list-item-content>
                  <v-list-item-title
                    class="text-h6 font-weight-normal blueGreen--text"
                    >Price per unit: {{ price.unit_price }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-subtitle-1">
                    {{ companyProducts.quantity }} KES per
                    {{ companyDetails.unit | lowercase }} &#9679; Min. Order
                    {{ price.min_units }}
                  </v-list-item-subtitle>
                  <v-list-item-title class="text-subtitle-1">
                    {{ price.delivery_cost }}</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-divider inset></v-divider>
            </v-list-item-group>
          </v-radio-group>
        </v-list>
        <v-card class="d-flex flex mb-5">
          <v-btn
            :disabled="!radios"
            class="flex py-8 text-h6 text-capitalize midnightGreen--text font-weight-bold"
            @click="expressInterest(radios)"
            depressed
            small
            color="primary"
            >Express Interest</v-btn
          >
        </v-card>
      </v-card>
    </v-card>
  </Card>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";

import Card from "@/components/Card";
import SuccessIcon from "@/components/SuccessIcon";
import BaseButton from "@/components/BaseButton";

export default {
  name: "Interest",
  components: {
    BaseButton,
    Card,
    SuccessIcon
  },
  data() {
    return {
      mine: false,
      radios: ""
    };
  },
  created() {
    this.fetchInterests();
    this.fetchCompanies();
    this.fetchSingleProduct({
      company: this.$route.query.company,
      product: this.$route.query.product
    });
    this.matchRoute();
    this.expressedInterest();
  },
  computed: {
    ...mapGetters(["isLoading"]),
    ...mapState([
      "companies",
      "productImageUuid",
      "pricing",
      "companyProducts",
      "companyDetails",
      "isInterested",
      "interests",
      "myCompanies"
    ])
  },
  methods: {
    ...mapMutations(["setValueOfIsInterested"]),
    ...mapActions([
      "fetchCompanies",
      "expressInterest",
      "fetchSingleProduct",
      "fetchInterests"
    ]),

    matchRoute() {
      const myCompany = this.myCompanies.find(
        company => parseInt(this.$route.query.company) === company.id
      );

      if (myCompany) {
        this.mine = true;
      }
    },
    expressedInterest() {
      if (!this.mine) {
        const result = this.interests.filter(interest => {
          return (
            parseInt(this.$route.query.product) === interest.product_id &&
            interest.status === "notified"
          );
        });

        this.setValueOfIsInterested(!!result.length);
      }
    }
  }
};
</script>
