var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Card',{attrs:{"displayControls":"","notifications":""}},[_c('ValidationObserver',{ref:"observer",staticClass:"d-flex flex flex-column justify-content-between",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',{staticClass:"px-6 pt-6 flex",attrs:{"flat":""}},[_c('h1',{staticClass:"midnightGreen--text"},[_vm._v(" Upload "),_c('br'),_vm._v("Product ")]),_c('v-form',[_c('ValidationProvider',{attrs:{"name":"Product name","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Product Name","error-messages":errors},model:{value:(_vm.product_name),callback:function ($$v) {_vm.product_name=_vm._n($$v)},expression:"product_name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Category","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.categories,"label":"Category","error-messages":errors,"required":""},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}})]}}],null,true)}),(_vm.category === 'Other')?_c('ValidationProvider',{attrs:{"name":"Other Category","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Other category","error-messages":errors,"type":"text"},model:{value:(_vm.category_other),callback:function ($$v) {_vm.category_other=$$v},expression:"category_other"}})]}}],null,true)}):_vm._e(),_c('ValidationProvider',{attrs:{"name":"Unit of measurement","mode":"eager","rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"input-field",attrs:{"type":"text","items":_vm.units,"error-messages":errors,"label":"Unit of Measurement","required":""},model:{value:(_vm.unit),callback:function ($$v) {_vm.unit=$$v},expression:"unit"}})]}}],null,true)}),(_vm.unit === 'Other')?_c('ValidationProvider',{attrs:{"name":"Unit of measurement","rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input",attrs:{"label":"Other unit of measurement","error-messages":errors,"type":"text"},model:{value:(_vm.unit_other),callback:function ($$v) {_vm.unit_other=$$v},expression:"unit_other"}})]}}],null,true)}):_vm._e(),_c('ValidationProvider',{attrs:{"name":"Quantity","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input",attrs:{"label":"Quantity","error-messages":errors,"type":"text"},model:{value:(_vm.quantity),callback:function ($$v) {_vm.quantity=_vm._n($$v)},expression:"quantity"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Location of product","rules":"required|min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input",attrs:{"label":"Location of Product","error-messages":errors,"type":"text"},model:{value:(_vm.location),callback:function ($$v) {_vm.location=$$v},expression:"location"}})]}}],null,true)})],1)],1),_c('BaseButton',{attrs:{"invalid":invalid,"buttonColor":"blueGreen","textColor":"white"},nativeOn:{"click":function($event){return _vm.addProduct({
          id: _vm.$route.params.id,
          data: {
            name: _vm.product_name,
            category: _vm.category_other ? _vm.category_other : _vm.category,
            unit: _vm.unit_other ? _vm.unit_other : _vm.unit,
            quantity: _vm.quantity,
            location: _vm.location
          }
        })}}},[_vm._v("Next")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }